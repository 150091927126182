import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiplomasService {

  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  diplomasAlumno(idAlumno:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "diploma/alumno/"+idAlumno,{headers});
  }

  diplomasCalendario(idCalendario:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "diploma/calendario/"+idCalendario,{headers});
  }
}
