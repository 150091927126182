import { Component, OnInit,  ViewChild } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { MaterialService } from 'src/app/tgservicios/material.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mostrar-material',
  templateUrl: './mostrar-material.component.html',
  styleUrls: ['./mostrar-material.component.css'],
  styles: [
    `:host ::ng-deep button { margin-right: .25em; }
    :host ::ng-deep .ui-message,
    :host ::ng-deep .ui-inputtext { margin-right: .25em; 
    :host ::ng-deep .custom-message,
    :host ::ng-deep img  { align-self: center; font-size: 16px; margin-left: .5em; }`
  ],
  providers: [MessageService]
})

export class MostrarMaterialComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload:any ; //permite manipular los metodos de <p-file>
  idCurso:string;
  idCalendario:string;
  nombreCurso:string;
  urlCurso:string;

  // Archivos
  uploadedFiles: any[] = [];
  archivos: any[] = []; 

  msgs: Message[] = []; //Mensajes de estatus
  verMaterial:any = "materialCurso";

  msgStatus:any;
  msgsNuevoMaterial: Message[] = []; //Mensaje de estatus de registro de material
  loading:boolean = true; //loading de carga de materiales
  loadingSaveMaterial:boolean = false; //loading de carga del guardado de material
  porcentajeCarga: number = 0;//porcentaje de carga en la subida de material
  displayFormulario:boolean = false; //Display del formulario de registro

  tiposMaterial:any; //Tipos de materiales
  materiales:any; //Materiales del curso
  material:any;
  materialesCompartidos:any; //Materiales compartidos del calendario

  statusMateriales:any = [{label: 'Compartido', value: 'true'}, {label: 'No compartido', value: 'false'}]

  //calendario donde estemos
  public compartirMarteialAlSubir=true;
  public tipoMaterial = {};
  public materialCurso = { nombre: "", descripcion:""};
  nombreMaterialNuevo: string = "";
  public materialNuevo:any[] = [];

  megas: number = environment.maxMBArchivo;

  constructor(private breadcrumbService:BreadcrumbService, private materialService:MaterialService, private messageService: MessageService,
  private activatedRoute:ActivatedRoute) {
    
    //Obtencion de la url del curso
    this.activatedRoute.params.subscribe(params =>{
      this.nombreCurso = params['nombreCurso'];
      this.urlCurso = params['urlCurso'];
      this.idCurso = params['idCurso'];
   
      if(params['idCalendario']!=undefined){
      this.idCalendario = params['idCalendario'];
    }else{
      this.compartirMarteialAlSubir=false;
    }
    })

    if(this.idCalendario!=undefined){
         //Valores del menu de rastro
      this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/']},
      { label: 'Cursos', routerLink: ['/cursos/'+localStorage.getItem("idUsuario")]},
      { label:  this.nombreCurso, routerLink: ['/curso/' + this.idCurso + '/' + this.nombreCurso + '/' + this.idCalendario]},
      { label: 'Material del curso'},
    ]);
    }else{
         //Valores del menu de rastro
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/']},
      { label: 'Cursos', routerLink: ['/cursos/'+localStorage.getItem("idUsuario")]},
      { label: 'Material '+this.nombreCurso},
    ]);
    }
 


  }

  ngOnInit(): void {
    this.obtenerTiposMaterial();
    if(this.idCalendario!=undefined){
      this.obtenerMateriales();
    
    }else{
      this.obtenerMaterialesUrlCurso();
  }
  }

  /**
   * Obtendra los materiales correspondientes al curso, y los asignara a un arreglo
   * en caso de error se mostrara el mensaje con el error correspondiente.
   * consulta los materiales de un curso por idCalendario indicando por cada materia si ya fue 
   * o no compartido
   */
  obtenerMateriales(){


    this.materialService.materialCurso(this.idCalendario).subscribe(materiales => {
      this.materiales = materiales;
      this.loading = false;
      //console.log(this.materiales);
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los materiales.', detail:'Detalles: '+error.name});
      }
    );
  }

  //consulta los material del curso por el url
  obtenerMaterialesUrlCurso(){
  this.materialService.materialCursoURl(this.urlCurso).subscribe(materiales => {
      this.materiales = materiales;
      this.loading = false;
      //console.log(this.materiales);
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los materiales.', detail:'Detalles: '+error.name});
      }
    );
  }
  /**
   * Obtendra los tipos de materiale, y los asignara a un arreglo para el proceso de registrar un nuevo material
   * en caso de error se mostrara el mensaje con el error correspondiente.
   */
  obtenerTiposMaterial(){
    this.materialService.tiposMaterial().subscribe(tiposMaterial => {
      this.tiposMaterial = tiposMaterial;
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error al obtener los tipos de materiales.', detail:'Detalles: '+error.name});
      }
    );
  }

  cargarNuevoMaterial(event:any){
    // console.log(this.materialNuevo);
  }

  obtenerNuevoMaterial(event:any){
    this.materialNuevo = event.currentFiles;
    this.nombreMaterialNuevo = event.currentFiles[0].name;
  }

  registrarNuevoMaterial(form: NgForm){
    if(form.invalid) {
      this.msgsNuevoMaterial = [];
      this.msgsNuevoMaterial.push({severity:'error', summary:'Error', detail:'Verifique la información.'});
    }else if (this.archivos.length > 0) {
      let formData: FormData = new FormData();
      this.loadingSaveMaterial=true;
      formData.append('curso', this.idCurso);
      formData.append('users', localStorage.getItem("idUsuario"));
      formData.append('nombre', this.materialCurso.nombre);
      formData.append('tipoMaterial', this.tipoMaterial["idTipoMaterial"]);
      formData.append('descripcion', this.materialCurso.descripcion);
      formData.append('file',this.archivos[0], this.archivos[0].nombre);
      

      this.materialService.nuevoMaterial(formData).subscribe(resultado => {

        if(this.idCalendario!=undefined){
        this.obtenerMateriales();
        }else{
          this.obtenerMaterialesUrlCurso();
        }
        this.msgs = [];
        
        this.msgs.push({severity:'success', summary:'Registro exitoso, ', detail:'Tu material se registro correctamente.'});

        this.materialCurso = { nombre: "", descripcion:""};
        this.nombreMaterialNuevo = "";
        this.materialNuevo = [];
        this.fileUpload.clear();
        this.tipoMaterial = {};

        this.displayFormulario = false;
        if(this.compartirMarteialAlSubir==true){
          this.compartirMaterial(resultado[0]["idMaterial"]);
        }
        this.loadingSaveMaterial=false;
      },error => {
        this.msgs = [];
        this.loadingSaveMaterial=false;
        this.displayFormulario = false;
        this.msgs.push({severity:'error', summary:'Error al subir el material.', detail:'Detalles: '+error.name});
      });

    } else if (this.archivos.length <= 0) {
      this.msgsNuevoMaterial = [];
      this.msgsNuevoMaterial.push({severity:'error', summary:'Error', detail:'Es necesario seleccionar un archivo'});
    }


    
  }

  compartirMaterial(idMaterial: string){
    var jsonData = JSON.stringify({"calendario" : { "idCalendario":this.idCalendario}, "materialCurso":idMaterial,"activo":true});
    this.materialService.compartirMaterial(jsonData).subscribe(resultado => {
      this.obtenerMateriales();
      this.msgs = [];
      this.msgs.push({severity:'success', summary:'Exito, ', detail:'tu material se publico correctamente a tus alumnos.'});
      setTimeout (() => { this.msgs =[]; }, 3000);
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al compartir el material.', detail:'Detalles: '+error.name});
      setTimeout (() => { this.msgs =[]; }, 3000);
    });
  }

  eliminarMaterialCompartido(idMaterialCompartido:string){
    this.materialService.eliminarMaterialCompartido(idMaterialCompartido).subscribe(resultado => {
      this.obtenerMateriales();
      this.msgs = [];
      this.msgs.push({severity:'success', summary:'Exito', detail:' , tu material se deshabilito correctamente'});
      setTimeout (() => { this.msgs =[]; }, 3000);
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al compartir el material.', detail:'Detalles: '+error.name});
      setTimeout (() => { this.msgs =[]; }, 3000);
    });
  }

  mostrarPantalla() { this.displayFormulario = true; }

  /* ARCHIVOS
   * Funcion "Upload" : 
   * @ param event 
   * recorre los archivo y los agrega al arreglo "uploadedFiles"
  */
  onUpload(event) {
    if (event.target.files.length > 0) {
      for (const file of event.files) { this.uploadedFiles.push(file); } 
    } 
  }

  onSelectedFile(event: any){
    /*Multiples Archivos*/
    if(event.currentFiles.length > 0 ){
      this.archivos = event.currentFiles;
    }
  }

  eliminarMaterial(material:any){
    let idUsuario = String(material.users['id']);

    if( idUsuario != localStorage.getItem("idUsuario") && localStorage.getItem("rolUsuario") != 'admin'){
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error ', detail:'Solo el administrador o el autor pueden eliminar este material.'});
      setTimeout (() => { this.msgs =[]; }, 5000);

    }else if(idUsuario === localStorage.getItem("idUsuario") || localStorage.getItem("rolUsuario") === 'admin'){
      this.loading = true;

      this.materialService.eliminarMaterial(material.idMaterial).subscribe(status =>{

      if(this.idCalendario!=undefined){ 
          this.materialService.materialCurso(this.idCalendario).subscribe(materiales => {
            this.materiales = materiales;
            this.loading = false;
            this.msgs = [];
            this.msgs.push({severity:'success', summary:'Exito, ', detail:' el material se elimino y se dejo de compartir en todos los cursos.'});
            setTimeout (() => { this.msgs =[]; }, 5000);
        },error => {
            this.msgs = [];
            this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los materiales.', detail:'Detalles: '+error.name});
            setTimeout (() => { this.msgs =[]; }, 5000);
          });
      }else{
        this.materialService.materialCursoURl(this.urlCurso).subscribe(materiales => {
          this.materiales = materiales;
          this.loading = false;
          this.msgs = [];
          this.msgs.push({severity:'success', summary:'Exito, ', detail:' el material se elimino '});
          setTimeout (() => { this.msgs =[]; }, 5000);
      },error => {
          this.msgs = [];
          this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los materiales.', detail:'Detalles: '+error.name});
          setTimeout (() => { this.msgs =[]; }, 5000);
        })
      }
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al compartir el material.', detail:'Detalles: '+error.name});
        setTimeout (() => { this.msgs =[]; }, 5000);
      });
      
    }
    //console.log(material);
  }

  validarMiMaterial(material:any){
    let idUsuario = String(material.users['id']);
    var estatus = false;
    if( idUsuario != localStorage.getItem("idUsuario") && localStorage.getItem("rolUsuario") != 'admin'){
      estatus = false;
    }else if(idUsuario === localStorage.getItem("idUsuario") || localStorage.getItem("rolUsuario") === 'admin'){
      estatus = true;
    }
    return estatus;
  }

}
