export class UsuarioModel{
  /** Valores obligatorios del modelo */
  id:number;
  nombreUsuario: string;
  email: string;
  password: string;
  rol: string;
  apiToken: string;

  /** Valores opcionales de uso moderado */
  idAlumno: number;
  idInstructor: number;
  phone:string;
  createdAt:Date;
  updatedAt:Date;
  deletedAt:Date;
  address:string;
  /** Valores opcionales de poco uso */
  
  alumno:string;
  cardBrand:string;
  cardLastFour:string;
  comentarioMaterials:any;
  comentariopublicacions:any;
  firstName:any;
  gender:any;
  locale:any;
  loginEnabled:any;
  materialCursos:any;
  name:any;
  image:any;
  oauthProvider:any;
  oauthUid:any;
  parentId:any;
  paypalEmail:any;
  
}