import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicacionService {
  
  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  obtenerPublicaciones(idCalendario: string ) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "publicacion/calendario/"+idCalendario,{headers});
  }

  obtenerComentarios(idPublicacion:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "publicacion/comentarios/"+idPublicacion,{headers});
  }

  registrarComentario(formData: any ) {
    return this.http.post(this.url + 'publicacion/save/comentario', formData,
      {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}}
    );
  }



  eliminarArchivoPublicacion(idArchivo:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.delete(this.url+ "publicacion/delete/archivo/"+idArchivo,{headers});
  }

}
