import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {

  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  todas(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "encuesta/encuestas-instructores/",{headers});
  }

  evaluacionesPorCalendario(idCalendario:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "encuesta/realizadas/"+idCalendario,{headers});
  }

  detallesEvaluacion(idQuality:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "encuesta/detalles-respuesta/"+idQuality,{headers});
  }

  obtenerEvaluacionCurso(tipo:string, idCalendario:string, idAlumno:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "encuesta/"+tipo+"/"+idCalendario+"/"+idAlumno,{headers});
  }

  
  /**
   * validad si el  alumno tiene encuestas pendientes por contestar 
   * retorna false si no tiene ,true si tiene
   * @param idAlumno 
   */
  validarEncuestasPendientes(idAlumno:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "encuesta/pendientes/"+idAlumno,{headers});
  }

  evaluacionesInstructor(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "instructor/evaluaciones/"+idInstructor,{headers});
  }

  evaluacionesInstructorSinAgrupar(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "instructor/evaluaciones/todas/"+idInstructor,{headers});
  }

  realizarEvaluacion(jsonData:any){
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.url + 'encuesta/save', jsonData, {headers});
  }
}
