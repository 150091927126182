import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { UsuarioService } from '../../../tgservicios/usuario.service';
import { SelectItem } from 'primeng/api/selectitem';
import { UsuarioModel } from 'src/app/tgmodelos/Usuario.model';

@Component({
  selector: 'app-mostrar-usuarios',
  templateUrl: './mostrar-usuarios.component.html',
  styleUrls: ['./mostrar-usuarios.component.css']
})
export class MostrarUsuariosComponent implements OnInit {
  usuario: UsuarioModel;
  usuarioSeleccionado: any;
  usuarios:any;
  
  displayDialog: boolean;
  sortOptions: SelectItem[];
  
  criterioBusqueda: string;
  
  constructor(private breadcrumbService: BreadcrumbService, private usuarioService:UsuarioService) {
    
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      { label: 'Usuarios' }
    ]);

    this.displayDialog = false;
  }

  ngOnInit(): void {
    this.usuarioService.todos().subscribe(usuarios=>{ this.usuarios = usuarios; });

    this.sortOptions = [
      {label: 'Administradores', value: 'Admin'},
      {label: 'Instructores', value: 'Instructor'},
      {label: 'Alumnos', value: 'Student'}
    ];
  }

  selectUsuario(event: Event, usuario: UsuarioModel){
    this.usuarioSeleccionado = usuario;
    this.displayDialog = true;
    event.preventDefault();
  }

  cerrarDetalles() {
    this.usuarioSeleccionado = null;
  }
}
