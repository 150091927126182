import {Component, Inject, forwardRef} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div class="logo-text">
				<img src="assets/img/logo64.png" alt="mirage-layout" />
				<div class="text">
					<h1>TG Alumnos</h1>
					<span>Tec Gurus &copy; 2020 </span>
				</div>
			</div>



			<div class="icons">
				<div class="icon icon-hastag">
					<a  routerLink="/"><i class="pi pi-home"></i></a>
				</div>
				<div class="icon icon-twitter">
				<a href="https://www.tecgurus.net/" target="_blank"><i class="pi pi-globe"></i></a>
				</div>
			</div>
        </div>
    `
})
export class AppFooterComponent {

}
