import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { Message, MessageService } from 'primeng/api';
import { BibliotecasService } from 'src/app/tgservicios/bibliotecas.service';
import { Router } from '@angular/router';
import { EvaluacionesService } from '../../../tgservicios/evaluaciones.service';

@Component({
  selector: 'app-mostrar-bibliotecas',
  templateUrl: './mostrar-bibliotecas.component.html',
  styleUrls: ['./mostrar-bibliotecas.component.css'],
  styles: [`
    :host ::ng-deep button {margin-right: .25em;}
    :host ::ng-deep .ui-message,
    :host ::ng-deep .ui-inputtext {margin-right: .25em;}
    :host ::ng-deep .custom-message,
    :host ::ng-deep img  {align-self: center; font-size: 16px; margin-left: .5em;}`
  ],
  providers: [MessageService]
})

export class MostrarBibliotecasComponent implements OnInit {
  msgs:Message[] = [];
  msgsInfoBiblioteca:Message[] = [];
  loading:boolean=true;
  tipoUsuario:string = localStorage.getItem("rolUsuario");

  bibliotecas:any;
  filtroBiblioteca:string="";

  terminosCondiciones:boolean = false;
  displayTerminosCondiciones:boolean = false;
  
  bloquearAcceso:boolean=false;

  filtroTalleres: any[]; //Filtro de busqueda de talleres
  selectedFiltroTaller: any = {"nombre":"Más recientes"};// Almacena la opción seleccionada en el p-dropdown

  constructor(private breadcrumbService:BreadcrumbService, private bibliotecasService:BibliotecasService
    ,private evaluacionesService: EvaluacionesService,
    private route:Router) {

    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Biblioteca'}
    ]);

    this.msgsInfoBiblioteca = [];
    this.msgsInfoBiblioteca.push({severity:'info', summary:' ', detail:'La biblioteca se usa de manera opcional y es complementaria a tu formación, el contenido aquí compartido pertenece a sus respectivos autores.'});
  }

  ngOnInit(): void {
    this.obtenerBibliotecas();
    if(this.tipoUsuario === "student"){
      this.validarEncuestasPendientesAlumno();
    }
    this.filtroTalleres = [  {nombre: 'Más recientes'}, {nombre: 'Mayor duración'}, {nombre: 'Menor duración'} ];
  }

  obtenerBibliotecas(){
    this.filtroBiblioteca = "";
      
    this.bibliotecasService.todas().subscribe(bibliotecas => {
      this.terminosCondiciones = bibliotecas['terminosAceptados'];
      this.displayTerminosCondiciones = !bibliotecas['terminosAceptados'];
      this.bibliotecas = bibliotecas['talleres'];
      this.bloquearAcceso= !bibliotecas['permitirAcceso'];
      this.loading = false;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las bibliotecas.', detail:'Detalles: '+error.name});
    });
    
  }

  filtrarBibliotecas(){
    if(this.filtroBiblioteca.length > 0){
        
      this.bibliotecasService.busqueda(this.filtroBiblioteca).subscribe(bibliotecas => { 
        this.bibliotecas = bibliotecas['talleres'];
        //console.log(this.bibliotecas);
        this.loading = false;
      },error => {
        this.loading = false;
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las bibliotecas.', detail:'Detalles: '+error.name});
      });

    }else{
      this.obtenerBibliotecas();
    }
    
  }

  aceptarTerminos(){
    this.bibliotecasService.aceptarTerminosCondiciones().subscribe(status =>{
      this.msgs = [];
      this.msgs.push({severity:'success', summary:'Exito ', detail:'Aceptaste los Términos y condiciones de biblioteca.'});
      this.terminosCondiciones = true;
      this.displayTerminosCondiciones = false;
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al aceptar los Términos y condiciones de biblioteca.', detail:'Detalles: '+error.name});
    });
  }

  
  validarEncuestasPendientesAlumno(){
    this.evaluacionesService.validarEncuestasPendientes(String(localStorage.getItem("idAlumno"))).subscribe(encuestasPendientes =>{
      if(encuestasPendientes===true) { 
        this.route.navigate(['/cursos/'+localStorage.getItem("idUsuario")],  { queryParams: { encuestaPendiente: true} });
      }
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar la encuesta.', detail:'Detalles: '+error.name});
    });
  }

  ordenarTalleres(tipo:any) {
    this.msgs = [];
    if(this.selectedFiltroTaller.nombre == 'Mayor duración'){
      this.bibliotecas.sort((a, b) => (a.duracion > b.duracion ? -1 : 1));
    }
    else if(this.selectedFiltroTaller.nombre == 'Menor duración'){
      console.log('Menor duración');
      this.bibliotecas.sort((a, b) => (a.duracion < b.duracion ? -1 : 1));
      
    }
    else if(this.selectedFiltroTaller.nombre == 'Más recientes'){
      this.bibliotecas.sort((a, b) => (a.fechaHora > b.fechaHora ? -1 : 1));
      console.log('Más recientes');
    }
  }

  
}
