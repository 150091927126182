import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agregar-material',
  templateUrl: './agregar-material.component.html',
  styleUrls: ['./agregar-material.component.css']
})
export class AgregarMaterialComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }

}
