import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { AutenticacionService } from 'src/app/tgservicios/autenticacion.service';
import { NotificacionesService } from 'src/app/tgservicios/notificaciones.service';
import { Message, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import {LazyLoadEvent,SelectItem} from 'primeng/api';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.html'
})
export class AppTopBarComponent {

		activeItem: number;
		nombreUsuario:string; rolUsuario:string;
		notificaciones:any;
		virtualNotificaciones:any;
		msgs:Message[] = [];
		
		notificacionesNuevas:number=0;
		constructor(public app: AppMainComponent,
			 private autenticacion:AutenticacionService,
			  private router:Router,
			  private notificicaionService:NotificacionesService) {}
		
		ngOnInit(): void {
			this.nombreUsuario = localStorage.getItem('nombreUsuario');
			this.rolUsuario = localStorage.getItem('rolUsuario');
			this.consultarUltimasNotificaciones();
		}

		perfil(){
			this.router.navigateByUrl('/perfil');
		}
		


		cerrarSesion(){
			this.autenticacion.logout();
		}

    mobileMegaMenuItemClick(index) {
        this.app.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
	}

	verNotificacion(notificacion:any){
		if(notificacion["vista"]==false){
		this.notificacionesNuevas=this.notificacionesNuevas-1;
		}
		notificacion["vista"]=true;
		var jsonData =JSON.stringify({ "idNotificacionVista":notificacion["idNotificacionVista"]});
		this.notificicaionService.verNotificacion(jsonData).subscribe(ver => { 
		  if(ver==true){
			 if(notificacion["tipoNotificacion"] !='Material compartido') {
			this.router.navigateByUrl(notificacion["link"]);
			 }
		  }else{
			this.msgs.push({severity:'error', summary:'Error  al  ver la notificacion', detail:''});
		  }
		},error => {
		  this.msgs = [];
		  this.msgs.push({severity:'error', summary:'Error  al  ver la notificacion', detail:''});
		});
	  }
	

	  
	//metodo que optine las ultimas 5 notificaciones
	consultarUltimasNotificaciones(){
		this.notificicaionService.obtenerUltimasNotificaciones().subscribe(notificaciones => { 
			this.notificaciones =notificaciones;
			
			//cuenta cuantas notificaiones no han sido vista
			for(let notificaion of this.notificaciones){
				if(notificaion["vista"] === false){ 
					this.notificacionesNuevas=this.notificacionesNuevas+1;	
				}
			}
		  },error => {
			this.msgs = [];
			this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las notificaciones.', detail:'Detalles: '+error.name});
		  });



		
	}

	loadCarsLazy(event: LazyLoadEvent) {       
        // simulate remote connection with a timeout 
      
            //load data of required page
            let loadedProducts = this.notificaciones.slice(event.first, (event.first + event.rows));

            //populate page of virtual cars
            Array.prototype.splice.apply(this.notificaciones, [...[event.first, event.rows], ...loadedProducts]);
            
            //trigger change detection
            this.notificaciones = [...this.notificaciones];
    }
	

}
