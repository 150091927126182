import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministradorService {

  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  obtenerDashboard(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "dashboard/administrador",{headers});
  }
}
