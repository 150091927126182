import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'archivoMegas'
})
export class ArchivoMegasPipe implements PipeTransform {

  transform(valorMegas: number): number {
    const valorBytes = valorMegas * 1024 * 1024;
    return valorBytes;
  }

}
