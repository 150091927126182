import { Component, OnInit } from '@angular/core';
import { NotificacionesService } from 'src/app/tgservicios/notificaciones.service';
import { Message, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../../breadcrumb.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html'
})
export class NotificacionesComponent implements OnInit {
  notificaciones:any;
  loading = true;
  msgs:Message[] = [];
		
  constructor(
    private breadcrumbService: BreadcrumbService,
    private router:Router,
    private notificicaionService:NotificacionesService
  ) {
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Mis notificaciones'}
    ]);
    

   }

  ngOnInit(): void {
    this.consultarNotificaciones();
  }

  consultarNotificaciones(){
		this.notificicaionService.obtenerNotificaciones().subscribe(notificaciones => { 
      this.notificaciones =notificaciones;
      this.loading = false;
			},error => {
        this.loading = false;
			this.msgs = [];
			this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las notificaciones.', detail:'Detalles: '+error.name});
		  });

  }


  verNotificacion(notificacion:any){
    notificacion["vista"]=true;
    var jsonData =JSON.stringify({ "idNotificacionVista":notificacion["idNotificacionVista"]});
    this.notificicaionService.verNotificacion(jsonData).subscribe(ver => { 
      if(ver==true){
        if(notificacion["tipoNotificacion"] !='Material compartido') {
			  this.router.navigateByUrl(notificacion["link"]);
        }
      }else{
        this.msgs.push({severity:'error', summary:'Error  al  ver la notificacion', detail:''});
      }
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error  al  ver la notificacion', detail:''});
    });
  }



  verTodasNotificacion(){
    
    this.notificicaionService.verTodasLasNotificaciones().subscribe(ver => { 
      if(ver==true){
        this.consultarNotificaciones();
        this.msgs.push({severity:'success', summary:'Exito, ', detail:'Todas las notificaciones fueron vistas'});
   
      }else{
        this.msgs.push({severity:'error', summary:'Error  al  ver las notificaciones', detail:''});
      }
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error  al  ver la notificacion', detail:''});
    });
  }
}
