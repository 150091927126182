import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../../../breadcrumb.service';
import { Message, MessageService } from 'primeng/api';
import { DiplomasService } from 'src/app/tgservicios/diplomas.service';
import { CursosService } from 'src/app/tgservicios/cursos.service';
import { Router } from '@angular/router';
import { EvaluacionesService } from '../../../tgservicios/evaluaciones.service';

@Component({
  selector: 'app-mostrar-diplomas',
  templateUrl: './mostrar-diplomas.component.html',
  styleUrls: ['./mostrar-diplomas.component.css'],
  styles: [`
    :host ::ng-deep button {margin-right: .25em;}
    :host ::ng-deep .ui-message,
    :host ::ng-deep .ui-inputtext {margin-right: .25em;}
    :host ::ng-deep .custom-message,
    :host ::ng-deep img  {align-self: center; font-size: 16px; margin-left: .5em;}`
  ],
  providers: [MessageService]
})

export class MostrarDiplomasComponent implements OnInit {

  tipoUsuario:string;
  msgs: Message[] = [];
  diplomas:any;

  displayDiplomasCalendario:boolean = false;
  cursosTerminados:any;
  diplomasCalendario:any;

  //Buscador y paginador
  loadingFiltro:boolean = false;
  nombreCursoFiltro:string;
  totalDePaginas:number = 1;
  totalDeCursos:number = 0;
  paginaActual:number = 1;

  constructor(private breadcrumbService: BreadcrumbService, private messageService: MessageService, private diplomasService:DiplomasService,
  private cursosService:CursosService,
  private evaluacionesService: EvaluacionesService,
     private route:Router
 
 ) {
    
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Diplomas'}
    ]);

    this.tipoUsuario = localStorage.getItem("rolUsuario");
  }

  ngOnInit(): void {
    if(localStorage.getItem("rolUsuario") === 'admin'){
      this.obtenerdiplomasAdministrador();
    }else if(localStorage.getItem("rolUsuario") === 'Instructor' && localStorage.getItem("idAlumno") === 'undefined'){
      this.msgs = [];
      this.msgs.push({severity:'info', summary:'', detail:'No estas registrado como un alumno.'});
    }else{
      this.validarEncuestasPendientesAlumno();

      this.obtenerDiplomasAlumno();
    }
  }

  validarEncuestasPendientesAlumno(){
    this.evaluacionesService.validarEncuestasPendientes(String(localStorage.getItem("idAlumno"))).subscribe(encuestasPendientes =>{
      if(encuestasPendientes===true) {
        this.route.navigate(['/cursos/'+localStorage.getItem("idUsuario")],  { queryParams: { encuestaPendiente: true} });
      }
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar la encuesta.', detail:'Detalles: '+error.name});
    });
  }

  obtenerDiplomasAlumno(){
    this.diplomasService.diplomasAlumno(localStorage.getItem("idAlumno")).subscribe(diplomas => {
      this.diplomas = diplomas;
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los diplomas.', detail:'Detalles: '+error.name});
    });
  }

  obtenerdiplomasAdministrador(){
    this.paginaActual = 1;
    this.cursosService.cursosTerminadosPorPagina(this.paginaActual).subscribe(cursosTerminados => {
      this.cursosTerminados = cursosTerminados[0]["hoja"];
      this.totalDePaginas = cursosTerminados[0]["noPaginas"];
      this.totalDeCursos = cursosTerminados[0]["noRegistros"];
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
    });
  }

  obtenerDiplomasCalendario(idCalendario:number){
    this.diplomasService.diplomasCalendario(idCalendario).subscribe(diplomas => {
      this.diplomasCalendario = diplomas;
      this.displayDiplomasCalendario = true;
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los diplomas.', detail:'Detalles: '+error.name});
    });
  }



  /** Busqueda de curso por nombre */
  buscarCursoNombre(){
    this.paginaActual = 1;
    this.loadingFiltro = true;
    if(this.nombreCursoFiltro != undefined && this.nombreCursoFiltro.length > 0){
      this.cursosService.cursosTerminadosPorPaginaFiltro(this.paginaActual, this.nombreCursoFiltro).subscribe(cursosTerminados => {
        this.cursosTerminados = cursosTerminados[0]["hoja"];
        this.totalDePaginas = cursosTerminados[0]["noPaginas"];
        this.totalDeCursos = cursosTerminados[0]["noRegistros"];
        this.loadingFiltro = false;
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
        this.loadingFiltro = false;
      });
    }
  }

  burcarCursoPagina(event:any){
    this.paginaActual = event.page + 1;
    this.loadingFiltro = true;

    if(this.nombreCursoFiltro != undefined && this.nombreCursoFiltro.length > 0){
      this.cursosService.cursosTerminadosPorPaginaFiltro(this.paginaActual, this.nombreCursoFiltro).subscribe(cursosTerminados => {
        this.cursosTerminados = cursosTerminados[0]["hoja"];
        this.totalDePaginas = cursosTerminados[0]["noPaginas"];
        this.totalDeCursos = cursosTerminados[0]["noRegistros"];
        this.loadingFiltro = false;
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
        this.loadingFiltro = false;
      });
    }else{
      this.cursosService.cursosTerminadosPorPagina(this.paginaActual).subscribe(cursosTerminados => {
        this.cursosTerminados = cursosTerminados[0]["hoja"];
        this.totalDePaginas = cursosTerminados[0]["noPaginas"];
        this.totalDeCursos = cursosTerminados[0]["noRegistros"];
        this.loadingFiltro = false;
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
        this.loadingFiltro = false;
      });
    }
  }

  borrarFiltro(){
    this.nombreCursoFiltro = "";
    this.obtenerdiplomasAdministrador();
  }
}
