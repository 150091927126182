import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutenticacionService } from '../tgservicios/autenticacion.service';
import {  Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private autenticacionService:AutenticacionService, private router:Router) {
     }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
       
        return next.handle(request).pipe(catchError(err => {
            
            if(  request.url.search("/login")!=-1  &&err.status === 401  ){
                console.clear();
                request.clone();
                return next.handle(request);
            }
            if (err.status === 401 ) {
                this.autenticacionService.logout();
                this.router.navigateByUrl('/iniciar-sesion');
                console.clear();
            }

            const error = err.error.message || err.statusText;
            
            return throwError(error);
        }))
    
    }
}