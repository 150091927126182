import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

// Application Components
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightPanelComponent} from './app.rightpanel.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

//Importaciones TG
import { PerfilComponent } from './tgcomponentes/usuario/perfil/perfil.component';
import { CursoComponent } from './tgcomponentes/cursos/curso/curso.component';
import { AsistenciasCursoComponent } from './tgcomponentes/cursos/asistencias-curso/asistencias-curso.component';
import { LoginComponent } from './tgcomponentes/Autenticacion/login/login.component';
import { MostrarUsuariosComponent } from './tgcomponentes/usuario/mostrar-usuarios/mostrar-usuarios.component';
import { MostrarDiplomasComponent } from './tgcomponentes/diplomas/mostrar-diplomas/mostrar-diplomas.component';
import { MostrarCursosComponent } from './tgcomponentes/cursos/mostrar-cursos/mostrar-cursos.component';
import { InicioComponent } from './tgcomponentes/Autenticacion/inicio/inicio.component';
import { EvaluarInstructorComponent } from './tgcomponentes/evaluaciones/evaluar-instructor/evaluar-instructor.component';
import { MostrarMaterialComponent } from './tgcomponentes/cursos-material/mostrar-material/mostrar-material.component';
import { AgregarMaterialComponent } from './tgcomponentes/cursos-material/agregar-material/agregar-material.component';
import { PublicacionDetalleComponent } from './tgcomponentes/cursos/publicacion-detalle/publicacion-detalle.component';
import { EvaluacionUsuarioComponent } from './tgcomponentes/evaluaciones/evaluacion-usuario/evaluacion-usuario.component';
import { MostrarBibliotecasComponent } from './tgcomponentes/bibliotecas/mostrar-bibliotecas/mostrar-bibliotecas.component';
import { BibliotecaDetallesComponent } from './tgcomponentes/bibliotecas/biblioteca-detalles/biblioteca-detalles.component';

// Servicios de la plantilla
import {BreadcrumbService} from './breadcrumb.service';
import {MenuService} from './app.menu.service';

// Servicios TG
import { CursosService } from './tgservicios/cursos.service';
import { AdministradorService } from './tgservicios/usuarios/administrador.service';
import { AlumnoService } from './tgservicios/usuarios/alumno.service';
import { InstructorService } from './tgservicios/usuarios/instructor.service';
import { PublicacionService } from './tgservicios/publicacion.service';
import { DiplomasService } from './tgservicios/diplomas.service';
import { EvaluacionesService } from './tgservicios/evaluaciones.service';
import { MaterialService } from './tgservicios/material.service';
import { RecuperarPasswordComponent } from './tgcomponentes/Autenticacion/recuperar-password/recuperar-password.component';
import { UrlSafePipe } from './tgpipes/url-safe.pipe';
import{AuthInterceptor} from './excepciones/AuthInterceptor';
import { NotificacionesComponent } from './tgcomponentes/notificaciones/notificaciones.component';
import { ArchivoMegasPipe } from './tgpipes/archivoMegas.pipe';

//Pipes TG




@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,

        //Importaciones TG
        CursoComponent,
        AsistenciasCursoComponent,
        LoginComponent,
        MostrarUsuariosComponent,
        MostrarDiplomasComponent,
        MostrarCursosComponent,
        InicioComponent,
        PerfilComponent,
        PublicacionDetalleComponent,
        EvaluacionUsuarioComponent,
        EvaluarInstructorComponent,
        MostrarMaterialComponent,
        AgregarMaterialComponent,
        RecuperarPasswordComponent,
        MostrarBibliotecasComponent,
        BibliotecaDetallesComponent,
        

        //Importacion de pipes de TG
        UrlSafePipe,
        ArchivoMegasPipe,
        

        NotificacionesComponent,
    ],
    providers: [
        {
            provide:
            LocationStrategy, useClass: HashLocationStrategy,
            
        },
        AuthInterceptor, {provide:HTTP_INTERCEPTORS,
            useClass:AuthInterceptor,
            multi:true

        },        
        BreadcrumbService,
        MenuService,
        
        // Importacion de servicios de TG
        CursosService,
        AdministradorService, AlumnoService, InstructorService,
        PublicacionService,
        DiplomasService,
        EvaluacionesService,
        MaterialService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
