import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { environment } from '../environments/environment';
import { Router} from '@angular/router';



@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[];

  constructor( public app: AppMainComponent, private router:Router) {}

  ngOnInit() {
    if(localStorage.getItem("rolUsuario") == "admin"){
      this.model = [
        {label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/']},
        {label: 'Usuarios', icon: 'pi pi-fw pi-users', routerLink: ['/usuarios']},
        {label: 'Cursos', icon: 'pi pi-fw pi-file-pdf', routerLink: ['/cursos/'+localStorage.getItem("idUsuario")]},
        {label: 'Biblioteca', icon: 'pi pi-fw pi-folder-open', routerLink: ['/biblioteca']},
        {label: 'Diplomas', icon: 'pi pi-fw pi-bookmark', routerLink: ['/diplomas']},
        {label: 'Evaluaciones', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/mis-evaluaciones']},
        {label: 'Examenes', icon:'pi pi-fw pi-file-o', url: [environment.urlExamenes], target: '_blank'},
        {label: 'Dudas o comentarios', icon:'pi pi-fw pi-question',
          items: [
            {label: 'contacto@tecgurus.net', icon: 'pi pi-fw pi-envelope', url : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas"},
            {label: '+ 52 5561868835', icon: 'pi pi-fw pi-comments', url : 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.', target :"_blanck"},
          ]
        }
      ];
    }else if(localStorage.getItem("rolUsuario") == "Instructor"){
      this.model = [
        {label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/']},
        {label: 'Cursos', icon: 'pi pi-fw pi-file-pdf', routerLink: ['/cursos/'+localStorage.getItem("idUsuario")]},
        {label: 'Biblioteca', icon: 'pi pi-fw pi-folder-open', routerLink: ['/biblioteca']},
        {label: 'Diplomas', icon: 'pi pi-fw pi-bookmark', routerLink: ['/diplomas']},
        {label: 'Evaluaciones', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/mis-evaluaciones']},
        {label: 'Examenes', icon:'pi pi-fw pi-file-o', url: [environment.urlExamenes], target: '_blank'},
        {label: 'Dudas o comentarios', icon:'pi pi-fw pi-question',
          items: [
            {label: 'contacto@tecgurus.net', icon: 'pi pi-fw pi-envelope', url : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas"},
            {label: '+ 52 5561868835', icon: 'pi pi-fw pi-comments', url :'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.', target :"_blanck"},
          ]
        }
      ];
    }  else if(localStorage.getItem("rolUsuario") == "library"){
      this.model = [
        {label: 'Biblioteca', icon: 'pi pi-fw pi-folder-open', routerLink: ['/biblioteca']},
        {label: 'Dudas o comentarios', icon:'pi pi-fw pi-question',
          items: [
            {label: 'contacto@tecgurus.net', icon: 'pi pi-fw pi-envelope', url : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas"},
            {label: '+ 52 5561868835', icon: 'pi pi-fw pi-comments', url : 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.', target :"_blanck"},
          ]
        }
      ];
    }else{
      this.model = [
        {label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/']},
        {label: 'Cursos', icon: 'pi pi-fw pi-file-pdf', routerLink: ['/cursos/'+localStorage.getItem("idUsuario")]},
        {label: 'Biblioteca', icon: 'pi pi-fw pi-folder-open', routerLink: ['/biblioteca']},
        {label: 'Diplomas', icon: 'pi pi-fw pi-bookmark', routerLink: ['/diplomas']},
        {label: 'Examenes', icon:'pi pi-fw pi-file-o', url: [environment.urlExamenes], target: '_blank'},
        {label: 'Dudas o comentarios', icon:'pi pi-fw pi-question',
          items: [
            {label: 'contacto@tecgurus.net', icon: 'pi pi-fw pi-envelope', url : "mailto:contacto@tecgurus.net?subject=Dudas o Comentarios&body=Hola vengo de la plataforma de alumnos tengo unas dudas"},
            {label: '+ 52 5561868835', icon: 'pi pi-fw pi-comments', url : 'https://api.whatsapp.com/send?phone=525562304805&text=Hola%20vengo%20de%20la%20plataforma%20de%20alumnos%20tengo%20algunas%20dudas.', target :"_blanck"},
          ]
        },
        {label:'Conoce ofertas de Empleo', url : [environment.urlTgEmpleos], target :"_blanck"},
      ];
    }
  }
    

  onMenuClick() {
    this.app.menuClick = true;
  }
}
