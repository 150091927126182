import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { ActivatedRoute } from '@angular/router';  
import { Message, MessageService } from 'primeng/api'
// importacion de servicios a usar 
import { AsistenciaAlumnoService } from '../../../tgservicios/asistencia-alumno.service';

@Component({
  selector: 'app-asistencias-curso',
  templateUrl: './asistencias-curso.component.html',
  styleUrls: ['./asistencias-curso.component.css'],
  styles: [`
    .loading-text {
    display: block;
    background-color: #f1f1f1;
    min-height: 19px;
    animation: pulse 1s infinite ease-in-out;
    text-indent: -99999px;
    overflow: hidden;
    }`
  ],
  providers: [MessageService]
})

export class AsistenciasCursoComponent implements OnInit {
  rolUsuario:string; // variable que almacena el rol del usuario
  idUsuario:string;  // almacena el id de usuario
  msgs: Message[] = []; // permite mostrar un msj de error
  email:string; // Almacena el email de un usuario en especifico
  datosPersonales:any[] = []; // Alamace la informacion de un usuario en especifico 
  idCalendario; //Varible que obtiene el id de calendario
  datosAsistencia:any[] =[];  //Arreglo que contiene toda la información de API
  datoFecha:any []=[];  //Arreglo que contiene las fechas de la API
  datosUsuario:any[] =[];  //Arreglo que contiene los datos de los Usuarios
  infoUsuario:any[] = [];  // Arreglo que contiene los datos de un usuario en especifico
  asistir:boolean= false; // Variable que permite almacenar el valor de la asistencia nueva 
  msjAsistencia:boolean = false;  //msj si la asistencia se guardo correctamente
  resCurso: any [] = []; // Almacena los cursos del usuario
  resAlumno: any [] = []; // Almacena los cursos del alumno
  cursoActual: any [] = []; // Almacena el curso actual
  nombreCurso:string; // Almancena el nombre del curso
  loadingCalendario:boolean = true; // carga de loading 
  idCurso:string ;// Almacena el id de curso 
  resultadoAsistencia:any[] =[]; // Almancena el valor de retorno del API
  anchoColumna:string;//es el ancho que se le dara a la tabla dependiendo de la cantidad de dias

  constructor(private activatedRoute: ActivatedRoute, private asistenciaService:AsistenciaAlumnoService, private breadcrumbService: BreadcrumbService) {
   
    // Obtener datos de la Url 
      this.activatedRoute.params.subscribe( params =>{
      this.idCalendario = ( params.idCalendario );
      this.idCurso =( params.idCurso );
      this.nombreCurso = ( params.nombreCurso );
      // console.log(this.idCalendario);
    //menu navegacional
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      { label: 'Cursos', routerLink:['/cursos/' + localStorage.getItem('idUsuario')]},
      { label:  this.nombreCurso, routerLink: ['/curso/' + this.idCurso + '/' + this.nombreCurso + '/'+ this.idCalendario]},
      { label: 'Asistencias'}
    ]);
    })

  }

  ngOnInit(): void {
    this.rolUsuario = localStorage.getItem('rolUsuario');
    this.idUsuario = localStorage.getItem('idUsuario');
    this.email = localStorage.getItem('email');
    this.obtenerAsistencia();
  }


  /**
  * Función que permite asignar a un nuevo arreglo la información de la API
  * Recupera la informacion de la API y se la asigna al arreglo "datosAsistencia"
  * posteriormente ejecuta las funciones de obtenerDatosUsuario(); y obtenerFecha();
  */
  obtenerAsistencia() { 
    this.asistenciaService.obtenerAsistencia(this.idCalendario).subscribe((asistencias:any) => { 
      this.datosAsistencia = asistencias;
      // console.log(this.datosAsistencia);
      this.obtenerDatosUsuario();
      this.obtenerFecha();
      if(this.rolUsuario == 'student'){
        this.obtenerDatoAlumno();
      }
      this.loadingCalendario = false;
    },(error) => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las asistencias.', detail:'Detalles: '+error.name});
    });
  }

  /*
  * Función que permite recurperar la informacion del alumno
  * posteriormente poder iterar en la tabla 
  */ 
  obtenerDatosUsuario(){
    for (let i = 0 ; i < this.datosAsistencia[0].asistencias.length; i++){
         this.datosUsuario.push (this.datosAsistencia[0].asistencias[i]);
    }
  }

  /*
  * Función que permite recurperar las fechas 
  * posteriormente poder iterar en la tabla y 
  */ 
  obtenerFecha(){
    for (let i = 0 ; i < this.datosAsistencia.length; i++){
         this.datoFecha.push ({fecha:this.datosAsistencia[i].fechaAsistencia, loading:false});

    }
    this.modificarDivFechas();
  }
  
  /** Funcion que permite buscar la asistencia 
   *  por fecha del alumno
  */
  buscarAsistencia(dia:string, id:string):boolean{
    let indiceFecha = this.datosAsistencia.findIndex(dato => dato.fechaAsistencia == dia);
    let indiceAlumno = this.datosAsistencia[indiceFecha].asistencias.findIndex(dato => dato.idInscripcion == id);
    let asistencia:boolean = this.datosAsistencia[indiceFecha].asistencias[indiceAlumno].asistencia;
    return asistencia;
  }
  
  /**
  * Función que permite modificar el valor de la asistencia 
  * en el arreglo de la informacion que trae la API
  * @ params:
  *  fecha : fecha que se requiere modificar
  *  id : id del alumno 
  *  asistencia:  nueva asistencia que introdusca el usuario
  */
   guardarAsistencia(fecha:string,lista:any, idInscripcion:string){
    // console.log(lista); // continene todo el objeto de la asistencia que se desea modificar
    lista.loading = true; // Cambiar icono de loading  
    let asistencia:boolean = lista.asistencia; // true  = registra false
    let jsonData = JSON.stringify(
      { "fechaAsistencia":fecha,
        "idCalendario":this.idCalendario,
        "asistencia":{"idInscripcion":idInscripcion,"asistencia":asistencia}
      }
    );
    // console.log(jsonData);
    // COMSUMIR API REGISTRAR ASISTENCIA
    this.asistenciaService.registrarAsistencia(jsonData).subscribe((data:any)=>{
    // console.log(data);
     // lista.asistencia = asistencia; 
      lista.asistencia = data.asistencia;
      lista.loading = false;  // quitar reload
      // console.log(this.datosAsistencia);
      this.msjAsistencia = true;
    },error=>{
       this.msgs = [];
       this.msgs.push({severity:'error', summary:'Error '+error.status+' al guardar las asistencias.', detail:'Detalles: '+error.name});
    });
   }


  // Obtener informacion de la asistenecia  por alumno 
  obtenerDatoAlumno(){
    let indiceAlumno = this.datosAsistencia[0].asistencias.findIndex(dato => dato.email == this.email); 
    this.datosPersonales.push(this.datosAsistencia[0].asistencias[indiceAlumno]);
  }

  modificarDivFechas(){
    const lenghtDatosFecha = this.datoFecha.length;
    if(lenghtDatosFecha > 10){
      this.anchoColumna = 'width:95px'
    }else{
      this.anchoColumna = 'width:40px'
    }
  }
}