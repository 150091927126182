// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  urlApi: "https://tg-alumnos-api.tgconsulting.online/",
  urlArchivosBiblioteca: "https://alumnos.tgconsulting.online/archivos/biblioteca/archivos/",
  urlExamenes: "https://examenes.tecgurus.net",
  urlTgEmpleos: "https://empleos.tecgurus.net",
  urlApiSite: "https://tg-api.tgconsulting.online/tg-api/publico/notificacion",
  maxMBArchivo: 100
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
