import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from '../../../tgmodelos/Usuario.model';
import { AutenticacionService } from 'src/app/tgservicios/autenticacion.service';
import { Router ,ActivatedRoute} from '@angular/router';
import {Message,MessageService} from 'primeng/api';
import { MenuItemContent } from 'primeng/menu';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [MessageService]
})

export class LoginComponent implements OnInit {
  dark: boolean; //Base de la plantilla
  checked: boolean;
  usuario: UsuarioModel;
  statusForm: boolean; mensajeForm: string; mensaje:string;
  msgs1: Message[];

  constructor(private autenticacion:AutenticacionService, private router:Router, private route: ActivatedRoute,
    private messageService: MessageService) {
    this.dark = true;
    this.statusForm = true;
    this.mensajeForm = "";
    this.mensaje="";
    this.usuario = new UsuarioModel();//inicializacion del modelo
    this.usuario.email = "";
    this.usuario.password = "";
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if( params['mensaje']!=null) this.msgs1 = [{severity:'success', summary:'Exito,', detail: params['mensaje']}];
    });

  }

  /**
   * Inicio de sesion
   * 
   */
  iniciarSesion(){
    this.statusForm = false;
    this.mensajeForm = "";
    var usuario:any;
    const emailTemporal = this.usuario.email.split(" ").join("");//quitar espacios
    this.usuario.email = emailTemporal;
    const passTemporal = this.usuario.password.split(" ").join("");//quitar espacios
    this.usuario.password = passTemporal;
    if(this.usuario.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) && this.usuario.password.length > 2 ){
      this.statusForm = true;
      this.autenticacion.login(this.usuario).subscribe(datosUsuario =>{
          localStorage.setItem('idUsuario',datosUsuario['idUsuario']);
          localStorage.setItem('idInstructor',datosUsuario['idInstructor']);
          localStorage.setItem('idAlumno',datosUsuario['idAlumno']);
          localStorage.setItem('nombreUsuario',datosUsuario['userName']);
          localStorage.setItem('email',datosUsuario['email']);
          localStorage.setItem('rolUsuario',datosUsuario['role']);
          localStorage.setItem('token',datosUsuario['token']);
          localStorage.setItem('expiracionToken',datosUsuario['fechaExpiracionToken']);
          if(datosUsuario['role']==="library"){
            this.router.navigateByUrl('/biblioteca');

          }else{
          this.router.navigateByUrl('/');
          }
      },
      error => {
        if(error.status === 401 ){
          this.mensaje="Error "
          this.mensajeForm = "El usuario o contraseña son erroneos.";
          this.statusForm = false;
          setTimeout (() => { this.statusForm = true; }, 3000);
        }else{
          this.mensaje="Error "
          this.mensajeForm = "Por el momento el servicio no esta listo.";
          this.statusForm = false;
          setTimeout (() => { this.statusForm = true; }, 3000);
        }
        
      });

    } else {
      this.mensaje="Error "
      this.mensajeForm = "El usuario o contraseña no son validos.";
      this.statusForm = false;
      setTimeout (() => { this.statusForm = true; }, 3000);
    }
  }

}
