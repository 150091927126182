import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/tgservicios/usuario.service';
import { UsuarioModel } from 'src/app/tgmodelos/Usuario.model';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  statusUpdatePerfil:string = 'void';
  usuario:UsuarioModel;
  tokenUsuario:string;nombreUsuario:string;email:String; rolUsuario:string;
  
  constructor(private usuarioService:UsuarioService, private breadcrumbService: BreadcrumbService) {

    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      { label: 'Perfil' }
    ]);

    this.usuario = new UsuarioModel();
    var datosTemp:any;

    this.usuarioService.perfilCompleto(parseInt(localStorage.getItem("idUsuario"))).subscribe(datos =>{
      datosTemp = datos;

      this.usuario.id = datosTemp.id;
      this.usuario.nombreUsuario = datosTemp.username;
      this.usuario.email = datosTemp.email;
      this.usuario.rol = datosTemp.rol;

      this.usuario.idAlumno = datosTemp.idAlumno;
      this.usuario.idInstructor = datosTemp.idInstructor;
      this.usuario.name = datosTemp.name;
      this.usuario.phone = datosTemp.phone;
      this.usuario.createdAt = datosTemp.createdAt;
      this.usuario.updatedAt = datosTemp.updatedAt;
      this.usuario.deletedAt = datosTemp.deletedAt;
      this.usuario.address = datosTemp.address;
    });
  }

  ngOnInit(): void {
    this.tokenUsuario = localStorage.getItem("token");
    this.nombreUsuario = localStorage.getItem("nombreUsuario");
    this.email = localStorage.getItem("email");
    this.rolUsuario = localStorage.getItem("rolUsuario");
   
  }

  solicitarActualizarInformacion(){
    this.statusUpdatePerfil = 'success';
  }

  actualizarInformacion(){
    let datosUsuario:FormData = new FormData();
    datosUsuario.append('id', String(this.usuario.id));
    datosUsuario.append('name', String(this.usuario.name));
    datosUsuario.append('phone', String(this.usuario.phone));
    datosUsuario.append('address', String(this.usuario.address));
    this.usuarioService.actualizarDatosPersonales(datosUsuario).subscribe(datos =>{
      this.statusUpdatePerfil = 'true';
    });
  }

}
