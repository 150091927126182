import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { SelectItem, Message } from 'primeng/api';
import { CursosService } from 'src/app/tgservicios/cursos.service';
import { InstructorService } from 'src/app/tgservicios/usuarios/instructor.service'
import { AdministradorService } from 'src/app/tgservicios/usuarios/administrador.service';
import { PublicacionService } from 'src/app/tgservicios/publicacion.service';
import { Router } from '@angular/router';
import { EvaluacionesService } from '../../../tgservicios/evaluaciones.service';

import { AutenticacionService } from 'src/app/tgservicios/autenticacion.service';
import { Banner } from 'src/app/tgmodelos/Banner';
import { NotificacionesService } from 'src/app/tgservicios/notificaciones.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html'
})

export class InicioComponent implements OnInit {
  idUsuario = localStorage.getItem("idUsuario");
  idInstructor = localStorage.getItem("idInstructor");
  idAlumno = localStorage.getItem("idAlumno");
  nombreUsuario = localStorage.getItem("nombreUsuario");
  email = localStorage.getItem("email");
  rolUsuario = localStorage.getItem("rolUsuario");
  token = localStorage.getItem("token");

  loading = true;
  msgs: Message[] = [];

  selectedYear: any;
  dropdownYears: SelectItem[];
  lineChartData: any;
  lineChartOptions: any;


  datosGraficaUsuarios: any;
  usuarios: any;
  cantidadTotalUsuarios: any;

  datosGraficaCursos: any;
  cursosProximos: any;
  cursosActivos: any;
  cantidadTotalCursos: any;

  /**variable para inicio de instructor  */
  cursosImpartidos: any;//guarda los cursos impartidos del instructor
  verDetallecursosImpartidos: boolean;
  cursosImpartidosDetalles: any;
  graficaCalificacionesEncuesta: any;
  evaluacionesInstructor: any;

  // variable para de uso para ruta de detalle publicaciones
  idCurso: any;
  idCalendario: any;
  nombreCurso: any;
  publicacionesCurso: any = [];
  displayPublicacionesCurso: boolean = false;

  /**variable para inicio de alumno  */
  ultimoCursoTerminado: any;
  ultimoCursoInscrito: any;

  /*varibales para modal de empleos */
  banner: Banner = {} as Banner;
  displayResponsive: boolean = false;
  modalTitle: string;
  modalMessage: string;
  modalUrlButton: string;
  longitudModal: string;

  constructor(private breadcrumbService: BreadcrumbService, private cursoService: CursosService, private instructorService: InstructorService,
    private administradorService: AdministradorService, private publicacionService: PublicacionService, private autenticacionService: AutenticacionService
    , private evaluacionesService: EvaluacionesService, private route: Router, private notificacionService: NotificacionesService
  ) {

    this.breadcrumbService.setItems([{ label: 'Inicio' }]);
    this.cantidadTotalUsuarios = "0";
  }

  ngOnInit(): void {
    if (localStorage.getItem("rolUsuario") === "admin") {
      this.cargarAdministrador();
    } else if (localStorage.getItem("rolUsuario") === "Instructor") {
      this.cargarInstructor();
    } else if (localStorage.getItem("rolUsuario") === "student") {
      this.validarEncuestasPendientesAlumno();
      this.cargarAlumno();
      this.obtenerNotificaciones();
    }
  }

  cargarAdministrador() {
    try {
      this.administradorService.obtenerDashboard().subscribe(datosAdministrador => {
        this.cursosImpartidos = datosAdministrador;
        this.loading = false;
      }, error => {
        this.loading = false;
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error ' + error.status + ' al obtener los cursos.', detail: 'Detalles: ' + error.name });
      });
    } catch (error) {
      console.error(error);
    }
  }

  cargarInstructor() {
    this.instructorService.obtenerDashboard(localStorage.getItem("idInstructor")).subscribe(datosInicio => {
      this.cursosImpartidos = datosInicio;
      //console.log(this,this.cursosImpartidos);
      this.loading = false;
      if (this.idCalendario != null) {
        var index = this.cursosImpartidos.findIndex(x => x.idCalendario == this.idCalendario);
        this.publicacionesCurso = this.cursosImpartidos[index]["publicaciones"];
      }
    }, error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Error ' + error.status + ' al obtener los cursos.', detail: 'Detalles: ' + error.name });
    });

  }

  /*********metodos para inicio de instructor    */
  seleccionarCursoImpartido(event: Event, curso: any) {
    this.verDetallecursosImpartidos = true;
    this.cursosImpartidosDetalles = curso["cursosImpartidos"];
    event.preventDefault();
  }

  //carga la grafica de  calificaciones de encuestas que aparece en el inicio de instrcutor
  cargarGraficaCalificacionesEncuestas() {
  }

  /*********metodos para inicio de alumnos    */
  cargarAlumno() {
    this.cursoService.ultimoCursoInscrito(this.idAlumno).subscribe((ultimoInscrito) => {
      this.ultimoCursoInscrito = ultimoInscrito;
    });

    this.cursoService.ultimoCursoTerminado(this.idAlumno).subscribe(ultimoTerminado => {
      this.ultimoCursoTerminado = ultimoTerminado;
    });
  }

  mostrarDisplayPublicacionesCurso(idCurso: any, nombreCurso: any, idCalendario: any, publicacionesCurso: any) {
    this.idCurso = idCurso;
    this.nombreCurso = nombreCurso;
    this.idCalendario = idCalendario;
    this.publicacionesCurso = publicacionesCurso;
    this.displayPublicacionesCurso = true;
    if (localStorage.getItem("rolUsuario") === "Instructor") {
      this.publicacionesCurso = null;
      this.cargarInstructor();
    }
  }

  validarEncuestasPendientesAlumno() {
    this.evaluacionesService.validarEncuestasPendientes(String(localStorage.getItem("idAlumno"))).subscribe(encuestasPendientes => {
      if (encuestasPendientes === true) {
        this.route.navigate(['/cursos/' + this.idUsuario], { queryParams: { encuestaPendiente: true } });
      }
    }, error => {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Error ' + error.status + ' al cargar la encuesta.', detail: 'Detalles: ' + error.name });
    });
  }

  async obtenerNotificaciones() {
    try {
      const response = await this.notificacionService.obtenerBanner();
      this.banner = response.data;
      if(this.banner){
        this.mostrarModalNotificacion(this.banner);
      }
    } catch (error) {
      console.error(error);
    }
  }

  mostrarModalNotificacion(banner: Banner): void {
    if (window.innerWidth < 500) {
      this.longitudModal = '90vw';
    } else {
      this.longitudModal = '40vw';
    }

    this.displayResponsive = true;
    this.modalTitle = banner.titulo
    this.modalMessage = banner.descripcion;
    this.modalUrlButton = banner.urlBoton;
  }

  goToTgEmpleo() {
    window.open(this.modalUrlButton);
  }

}
