import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthInterceptor} from '../excepciones/AuthInterceptor';

@Injectable({
  providedIn: 'root'
})

export class CursosService{
  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  /** Servicios generales */
  obtenerCursos() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "cursos/activos",{headers});
  }

  cursosProximos() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "cursos/proximos",{headers});
  }

  cursosActivos() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "cursos/activos",{headers});
  }

  detallesCalendario(idCalendario:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "calendario/datos-generales/"+idCalendario,{headers});
  }

  /** Servicios para administrador  */
  cursosIniciados(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "calendario/cursos-iniciados/",{headers});

  }

  cursosTerminadosPorPagina(noPagina:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "calendario/cursos-terminados/"+noPagina,{headers});
  }

  cursosTerminadosPorPaginaFiltro(noPagina:any,filtro:string){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "calendario/cursos-terminados/"+noPagina+"/"+filtro,{headers});
  }

  /** Servicios para instructor  */

  cursosInstructor(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "instructor/cursos/"+idInstructor,{headers});
  }

  todosInstructor(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "instructor/cursos-impartidos/"+idInstructor,{headers});
  }

  /** Servicios para alumnos  */

  cursosAlumno(idAlumno:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "inscripciones/alumno/"+idAlumno,{headers});
  }

  ultimoCursoInscrito(idAlumno:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "inscripciones/alumno/ultimo-inscrito/"+idAlumno,{headers});
  }

  ultimoCursoTerminado(idAlumno:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "inscripciones/alumno/ultimo-terminado/"+idAlumno,{headers});
  }

}
