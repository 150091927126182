import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BibliotecasService {

  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  busquedaById(idBiblioteca:any){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+"biblioteca/detalle/"+idBiblioteca,{headers});
  }

  todas(){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+"biblioteca/todas/",{headers});
  }

  busqueda(criterio:any){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+"biblioteca/todas/"+criterio,{headers});
  }

  temarioBiblioteca(idBiblioteca:any){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+"biblioteca/temario/"+idBiblioteca,{headers});
  }

  registrarVistaSubtema(jsonData:any){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.url+"biblioteca/guardar-vista-subtema",jsonData,{headers});
  }

  aceptarTerminosCondiciones(){
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.post(this.url+"biblioteca/aceptar-terminos-y-condiciones",null,{headers});
  }
}
