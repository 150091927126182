import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';

import { TareaModel } from '../../../tgmodelos/Tarea.model';
import { Message, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { InstructorService } from '../../../tgservicios/usuarios/instructor.service';
import { PublicacionService } from 'src/app/tgservicios/publicacion.service';
import { CursosService } from 'src/app/tgservicios/cursos.service';
import { MaterialService } from 'src/app/tgservicios/material.service';
import { EvaluacionesService } from '../../../tgservicios/evaluaciones.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.css'],
  providers: [MessageService]
})

export class CursoComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload:any ; //permite manipular los metodos de <p-file>
  msgs: Message[] = []; // permite mostrar un msj de error
  idCalendario: string; // variable que se le asigna el id calendario de la ruta
  idUsuario: string; // variable que se recupera el id usuario
  rolUsuario: string; // variable que recupera el rol del usuario
  resPublicacion: any [] = []; // Almacena las publicaciones
  resCurso: any [] = []; // Almacena los cursos del usuario
  resAlumno: any[] = []; // Almacena los cursos del alumno
  cursoActual: any [] = []; // Almacena el curso actual
  mostrarmsj:boolean = false;
  msjform :boolean = false;
  nombreCurso: string;
  modificar: boolean = false; // Variable para modificar
  idPublicacion: string; // Variable para almacenar el id publicacion
  curso: any[] = [];   // Variable de tipo any donde se almacenara el curso a mostrar detalle
  tarea: TareaModel; //Varable tarea para el uso del modelo tarea
  imagen: string;// Variable donde se almacena la ruta de la imagen
  evaluacion: boolean = false;
  valorActualidad: number = 0; // Variable que almacenara la calificacion de la actualidad de la informacion
  valorTemario: number = 0; // Variable que almacenara la calificacion del temario
  comentario: string = '';   // Variable que almacenara el contenido del comentario
  hoy: Date = new Date(); // Variable que obtiene la fecha del sistema 
  idCurso: number; // Variable que obtiene el id del curso y pasa como parametro a la pagina de asistencia
  displayArchivo:boolean;  // variable que permite mostrar la pantalla de subir archivo
  uploadedFiles: any[] = [];
  archivos: any[] = []; 
  urlCurso: string;
  loadingSavePublicacion:boolean = false; //loading en el guardado de publicación
  materialesCurso:any;
  datosGenerales:any = [];//gaurda los datos generales del calendario
  publicacion : any[] = [];

  materialesCursoModificado:any = []; //Variable para almacenar los archivos de una publicacion que se modifica

  megas: number = environment.maxMBArchivo;

  constructor(private activatedRoute: ActivatedRoute,
    private cursoService:CursosService,
    private instructorService: InstructorService,
    private _sanitizer: DomSanitizer,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private _publicacionService: PublicacionService,
    private materialService:MaterialService,
    private evaluacionesService: EvaluacionesService,
    private route:Router) {

    this.idUsuario = localStorage.getItem('idUsuario');
    this.rolUsuario = localStorage.getItem('rolUsuario');
    // obtener datos de ruta
    this.activatedRoute.params.subscribe ( params => {
      this.nombreCurso = ( params.nombreCurso );
      this.idCurso = ( params.idCurso );
      this.idCalendario = ( params.idCalendario );
    });

    //menu navegacional
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Cursos', routerLink: ['/cursos/' + localStorage.getItem('idUsuario')]},
      {label: this.nombreCurso}
    ]);

    this.obtenerDetallesCalendario();
    this.obtenerMateriales();
  }

  ngOnInit(): void {
    if(this.rolUsuario === "student"){
        this.validarEncuestasPendientesAlumno();
      }
    
    this.tarea = new TareaModel(); //inicializacion del modelo tarea
    this.obtenerPublicaciones();

  } 

  // Metodo que obtinene los cursos impartidos del profesor
  obtenerDetallesCalendario() {
    this.cursoService.detallesCalendario(this.idCalendario).subscribe(detallesCurso => {
      this.datosGenerales=detallesCurso;
      this.urlCurso = detallesCurso["url"];
      this.nombreCurso = detallesCurso["nombreCurso"];
    }, error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los datos del calendario.', detail:'Detalles: '+error.name});
    });
  }


  // Metodo que obtiene las publicaciones del curso
  obtenerPublicaciones() {
    this._publicacionService.obtenerPublicaciones(this.idCalendario).subscribe(
      (info: any) => {
        this.resPublicacion = info;
      },
      (error) => {
         //console.log(error);
         this.msgs = [];
         this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener datos de las publicaciones.', detail:'Detalles: '+error.name});
      }
    );
  }

  /*
  * Metodo para sanitizar las imagenes que son provenientes de url externas
  * imagen: variable que recibe la url de la imagen
  */
  getBackground(image) {
    // tslint:disable-next-line: max-line-length
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(${'../../../../assets/img/curso02.jpg'})`);
  }

  //  --------- SUBIR UNA TAREA NUEVA
  /*
  * Funcion que permite mostrar la ventana emergente de subir archivo 
  * Se ejecuta al dar click en el boton Subir tarea
  */ 
  mostrarPantalla() {
  this.displayArchivo = true;
  this.fileUpload.clear();
  this.mostrarmsj = false ;
  }

  /*
  * Funcion "guardar" : se ejecuta al dar click en el boton submit del formulario
  * Almacena los valores del formulario dentro de un array "NuevaTarea[]"
  * posteriormente limpia el formulario
  */
  
  guardar( form: NgForm){
    if (form.invalid) {
      this.msjform = true;
    } else {
      this.loadingSavePublicacion=true; //Iniciamos el loading

      // recuperar la información  y mandar a la API
      let formData: FormData = new FormData();
      if (this.archivos.length == 0) {
        formData.append('users', this.idUsuario);
        formData.append('calendario', this.idCalendario);
        formData.append('nombrePublicacion', this.tarea.nombretarea);
        formData.append('texto', this.tarea.descripciontarea);
        //mandar petición a la API sin archivo
      } else {
        formData.append('users', this.idUsuario);
        formData.append('calendario', this.idCalendario);
        formData.append('nombrePublicacion', this.tarea.nombretarea);
        formData.append('texto', this.tarea.descripciontarea);
        //mandar petición a la API con archivo
        /* Archivos multiples */
        for (let i = 0; i < this.archivos.length; i++) { formData.append('file',this.archivos[i], this.archivos[i].name); }
      }
      
      if (this.modificar) { formData.append('idPublicacion', this.idPublicacion); }

      /* Consumir la API Registrar la publicacion */
      this.instructorService.registrarTarea(formData).subscribe((data:any) => {
        this.publicacion = data;
        this.modificar = false;
        this.ngOnInit(); // Se ejecuta el metodo para el refresh de a pagina
        this.limpiarFormulario(form); // Limpiar el formulario
        this.archivos= []; //Limpiamor el arreglo de archivos
        this.loadingSavePublicacion=false; //Finalizamos el loading
        this.displayArchivo = false; //Cerramos el formulario
        this.msgs = [];
        this.msgs.push({severity:'success', summary:'Exito,', detail:' Tu publicacion se guardo correctamente'});
      },error=>{
        this.loadingSavePublicacion=false; //Finalizamos el loading
        this.displayArchivo=false; //Cerramos el formulario
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al guardar la publicación.', detail:'Detalles: '+error.name});
      });
    }
   }
  

  /*
  * Funcion "Upload" : 
  * @ param event 
  * recorre los archivo y los agrega al arreglo "uploadedFiles"
  */
  onUpload(event) {
    if (event.target.files.length > 0) {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
      } 
    } 
  }

  onSelectedFile(event: any){
    /*Multiples Archivos*/
    if(event.currentFiles.length > 0 ){
      this.archivos = event.currentFiles;
    }
  }
   
  limpiarFormulario(formulario:NgForm){
    /**Limipiar todos los archivos archivos */
    formulario.reset();
    this.fileUpload.clear();
  }

  // Modificar una publicacion
  modificarTarea(idPublicacion: string) {
    this.idPublicacion = idPublicacion;
    let nuevoValor:number = this.resPublicacion.findIndex(dato => dato.idPublicacion === idPublicacion);
    this.materialesCursoModificado = this.resPublicacion[nuevoValor].archivos;
    this.tarea.nombretarea = this.resPublicacion[nuevoValor].nombrePublicacion;
    this.tarea.descripciontarea = this.resPublicacion[nuevoValor].texto;
    this.displayArchivo = true;
    this.modificar = true;
  }

  // Eliminar una publicacion 
  eliminarTarea(idPublicacion:number){ 
    let indice:any; // cambiar icono de loading
    indice = this.resPublicacion.findIndex(dato => dato.idPublicacion == idPublicacion);
    this.resPublicacion[indice].loading = true;
    
    this.instructorService.eliminarTarea(idPublicacion).subscribe((data:any) => {
      if (data == true) {
        this.ngOnInit();
        this.msgs = [];
        this.msgs.push({severity:'success', summary:'Exito, ', detail:' La publicacion se elimino correctamente.'});
      }else{
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error, ', detail:' La publicacion no se elimino.'});
      }
    },error=>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al eliminar la publicación', detail:'Detalles: '+error.name});
    });
    
  }

  obtenerMateriales(){
    this.materialService.materialCompartido(this.idCalendario).subscribe(materiales => {
      this.materialesCurso = materiales;
      // console.log(this.materialesCurso);
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los materiales.', detail:'Detalles: '+error.name});
      }
    );
  }


  validarEncuestasPendientesAlumno(){
    this.evaluacionesService.validarEncuestasPendientes(String(localStorage.getItem("idAlumno"))).subscribe(encuestasPendientes =>{
      if(encuestasPendientes===true) {
        this.route.navigate(['/cursos/'+this.idUsuario],  { queryParams: { encuestaPendiente: true} });
      }
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar la encuesta.', detail:'Detalles: '+error.name});
    });
  }


  eliminarArchivoPublicacion(idArchivo:any){
    this._publicacionService.eliminarArchivoPublicacion(idArchivo).subscribe(status => {

      this._publicacionService.obtenerPublicaciones(this.idCalendario).subscribe((publicaciones:any) => {
        this.resPublicacion = publicaciones;
        this.displayArchivo = false;
        this.msgs = [];
        this.msgs.push({severity:'success', summary:'Exito, ', detail:' el material se elimino de la publicación.'});
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener datos de las publicaciones.', detail:'Detalles: '+error.name});
      });
      
      
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al eliminar la publicación.', detail:'Detalles: '+error.name});
    });
  }
}
