import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { EvaluacionesService } from 'src/app/tgservicios/evaluaciones.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-evaluacion-usuario',
  templateUrl: './evaluacion-usuario.component.html',
  styleUrls: ['./evaluacion-usuario.component.css'],
  providers: [MessageService]
})
export class EvaluacionUsuarioComponent implements OnInit {

  msgs: Message[] = [];
  msgsDetalles: Message[] = [];
  msgsDetalleEvaluacion: Message[] = [];

  evaluaciones:any;
  evaluacionesSinAgrupar:any;
  detallesEvaluaciones:any;
  nombreEvaluacion:string;
  detalleEvaluacion:any = [];
  datosEncuesta:any = [];

  evaluacionesChartData:any;
  evaluacionesChartOptions:any;
  totalCursos:any;

  loading: boolean = true;
  loadingDetallesEvaluaciones:Boolean = false;
  loadingDetalleEvaluacion:Boolean = false;
  
  displayDetallesEvaluaciones:boolean = false;
  displayDetalleEvaluacion:boolean = false;

  tipoUsuario:string;

  constructor(private breadcrumbService:BreadcrumbService, private evaluacionesService:EvaluacionesService, private messageService: MessageService) {
    
    //Valores del menu de rastro
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/']},
      { label: 'Evaluaciones' }
    ]);

    this.tipoUsuario = localStorage.getItem("rolUsuario");
  }

  ngOnInit(): void {
    if(this.tipoUsuario === "admin"){
      this.cargarAdministrador();
    }else if(this.tipoUsuario === "Instructor"){
      this.cargarInstructor();
    }
    
  }

  cargarAdministrador(){
    this.evaluacionesService.todas().subscribe(datosEvaluaciones =>{
      this.evaluaciones = datosEvaluaciones;
      this.loading = false;
    });
  }


  cargarInstructor(){
    //Solicitud de servicio para traer las evalauciones del instructor
    this.evaluacionesService.evaluacionesInstructor(localStorage.getItem("idInstructor")).subscribe(evaluaciones=>{
      this.evaluaciones = evaluaciones;
      this.cargarGraficasInstructor();
      this.loading = false;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar las evaluaciones. ', detail:' Detalles: '+error.name});
    });

    this.evaluacionesService.evaluacionesInstructorSinAgrupar(localStorage.getItem("idInstructor")).subscribe(evaluaciones=>{
      this.evaluacionesSinAgrupar = evaluaciones;
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar las evaluaciones. ', detail:' Detalles: '+error.name});
    });
  }

  cargarGraficasInstructor(){
    
    let labelsGrafica:any[] = [];
    let promediosIntermedios:any[] = [];
    let promediosFinales:any[] = [];

    let i:number=0;
    //console.log(this.evaluaciones);

    this.evaluaciones.forEach(evaluacion => {
      let evaluacionesIntermedias:any[]=[];
      let evaluacionesFinales:any[] = [];
      let totalEvaluacionesIntermedias = 0;
      let totalEvaluacionesFinales = 0;

      labelsGrafica.push(evaluacion.nombreCurso);

      evaluacion.evaluaciones.forEach(valorEvaluacion => {
        if(valorEvaluacion.promedioEncuestaIntermedia > 0) evaluacionesIntermedias.push( valorEvaluacion.promedioEncuestaIntermedia.toFixed(2) );
        if(valorEvaluacion.promedioEncuestaFinal > 0)  evaluacionesFinales.push( valorEvaluacion.promedioEncuestaFinal.toFixed(2) ); 
      });

      for(let i = 0; i < evaluacionesIntermedias.length; i++) totalEvaluacionesIntermedias += parseFloat(evaluacionesIntermedias[i]);
      for(let i = 0; i < evaluacionesFinales.length; i++) totalEvaluacionesFinales += parseFloat(evaluacionesFinales[i]);
      
      promediosIntermedios.push( (totalEvaluacionesIntermedias / evaluacionesIntermedias.length).toFixed(2) );
      promediosFinales.push( (totalEvaluacionesFinales / evaluacionesFinales.length).toFixed(2) );
    });

    this.evaluacionesChartData = {
      labels: labelsGrafica,
      datasets: [
        {
          label: 'Promedio intermedio',
          backgroundColor: '#EBCB8B',
          borderColor: '#EBCB8B',
          data: promediosIntermedios
        },{
          label: 'Promedio final',
          backgroundColor: '#81A1C1',
          borderColor: '#81A1C1',
          data: promediosFinales
        }
      ]
    };

    this.evaluacionesChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
      hover: { mode: 'index' },
      scales: {
        xAxes: [{
          gridLines: { display: false },
          ticks: { fontColor: '#000000', fontSize:6, padding:0}
        }],
        yAxes: [{
          gridLines: {  display: false },
          ticks: { fontColor: '#9199a9', min: 50, max: 100}
        }]
      },
      legend: { display: true, labels: { fontColor: '#9199a9' } }
    };
  }

  ventanaDetallesEvaluaciones(idCalendario:any, nombreEvaluacion:string){
    this.nombreEvaluacion = nombreEvaluacion;
    this.loadingDetallesEvaluaciones = true;    
    this.displayDetallesEvaluaciones = true;

    this.evaluacionesService.evaluacionesPorCalendario(idCalendario).subscribe(detallesEvaluaciones=>{
      this.detallesEvaluaciones = detallesEvaluaciones;
      this.loadingDetallesEvaluaciones = false;
   
        },error => {
      this.msgsDetalles = [];
      this.msgsDetalles.push({severity:'error', summary:'Error '+error.status+' al cargar los detalles de las evaluaciones. ', detail:' Detalles: '+error.name});
      this.loadingDetallesEvaluaciones = false;
    });
  }

  ventanaDetalleEvaluacion(idQuality:any){
    this.loadingDetalleEvaluacion = true;
    this.displayDetalleEvaluacion = true;

    this.evaluacionesService.detallesEvaluacion(idQuality).subscribe(detalleEvaluacion=>{
      this.detalleEvaluacion = detalleEvaluacion;
      this.datosEncuesta = detalleEvaluacion["datosEncuesta"][0];
      this.loadingDetalleEvaluacion = false;
      //console.log("detalles evaluacion ")   

      if(this.tipoUsuario === "Instructor"){
        //console.log(this.detalleEvaluacion);
        if(this.detalleEvaluacion["tipoEncuesta"]==="Intermedia"){
          //elimina las preguntas que son de evalaucion para tecgurus
          this.detalleEvaluacion["respuestas"][2]["pregunta"]="";
          this.detalleEvaluacion["respuestas"][2]["respuesta"]="";
          this.detalleEvaluacion["respuestas"][4]["pregunta"]="";
          this.detalleEvaluacion["respuestas"][4]["respuesta"]="";
          this.detalleEvaluacion["respuestas"][4]["comentario"]="";
          //recorre numeracion de preguntas por las que se eliminaron
          this.detalleEvaluacion["respuestas"][3]["pregunta"]=
          this.detalleEvaluacion["respuestas"][3]["pregunta"].replace("4)","3)");
         
        }else{
          this.detalleEvaluacion["respuestas"][4]["pregunta"]="";
          this.detalleEvaluacion["respuestas"][4]["respuesta"]="";
          this.detalleEvaluacion["respuestas"][4]["comentario"]="";
    
          this.detalleEvaluacion["respuestas"][5]["pregunta"]="";
          this.detalleEvaluacion["respuestas"][5]["respuesta"]="";
          this.detalleEvaluacion["respuestas"][5]["comentario"]="";
        }
      }
    },error => {
      this.msgsDetalleEvaluacion = [];
      this.msgsDetalleEvaluacion.push({severity:'error', summary:'Error '+error.status+' al cargar los detalles de las evaluaciones. ', detail:' Detalles: '+error.name});
      this.loadingDetalleEvaluacion = false;
    });
  }

}
