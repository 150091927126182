//Importacion del framework y plantilla
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';

//Importacion de autenticacion
import { LoginComponent } from './tgcomponentes/Autenticacion/login/login.component';
import { RecuperarPasswordComponent } from './tgcomponentes/Autenticacion/recuperar-password/recuperar-password.component'
import { InicioComponent } from './tgcomponentes/Autenticacion/inicio/inicio.component';

//Importacion de cursos
import { MostrarCursosComponent } from './tgcomponentes/cursos/mostrar-cursos/mostrar-cursos.component';
import { CursoComponent } from './tgcomponentes/cursos/curso/curso.component';
import { AsistenciasCursoComponent } from './tgcomponentes/cursos/asistencias-curso/asistencias-curso.component';

//Importacion de usuario
import {PerfilComponent } from './tgcomponentes/usuario/perfil/perfil.component';
import {MostrarUsuariosComponent } from './tgcomponentes/usuario/mostrar-usuarios/mostrar-usuarios.component';

//Importacion de diplomas
import { MostrarDiplomasComponent } from './tgcomponentes/diplomas/mostrar-diplomas/mostrar-diplomas.component';
import { AutenticacionGuard } from './tgguardias/autenticacion.guard';

//Importacion de publiaciones
import { PublicacionDetalleComponent  } from './tgcomponentes/cursos/publicacion-detalle/publicacion-detalle.component';

//Importacion de evaluaciones
import { EvaluacionUsuarioComponent } from './tgcomponentes/evaluaciones/evaluacion-usuario/evaluacion-usuario.component';

//Importacion del curso
import { MostrarMaterialComponent } from './tgcomponentes/cursos-material/mostrar-material/mostrar-material.component';

//Importacion del biblioteca
import { MostrarBibliotecasComponent } from './tgcomponentes/bibliotecas/mostrar-bibliotecas/mostrar-bibliotecas.component';
import { BibliotecaDetallesComponent } from './tgcomponentes/bibliotecas/biblioteca-detalles/biblioteca-detalles.component';

import { NotificacionesComponent } from './tgcomponentes/notificaciones/notificaciones.component';

export const routes: Routes = [
    { path: '', component: AppMainComponent,
        children: [            
            { path: '', component: InicioComponent},
            //Rutas de usuario
            { path: 'usuarios', component: MostrarUsuariosComponent},
            { path: 'perfil', component: PerfilComponent},
            // Rutas de cursos
            { path: 'cursos/:id', component: MostrarCursosComponent},
            { path: 'curso/:idCurso/:nombreCurso/:idCalendario', component: CursoComponent},
            { path: 'asistencia/:nombreCurso/:idCurso/:idCalendario', component: AsistenciasCursoComponent},
            { path: 'diplomas', component: MostrarDiplomasComponent},
            //Rutas de publicaciones
            { path: 'curso/publicacion/:nombreCurso/:idCurso/:idCalendario/:nombrePublicacion/:idPublicacion', component: PublicacionDetalleComponent},
            //Rutas de evaluaciones
            { path: 'mis-evaluaciones', component: EvaluacionUsuarioComponent},
            //Material del curso
            { path: 'curso/material/:idCurso/:nombreCurso/:urlCurso/:idCalendario', component: MostrarMaterialComponent},


             //Material del curso por url
             { path: 'curso/material/:idCurso/:nombreCurso/:urlCurso', component: MostrarMaterialComponent},

            //Bibliotecas
            { path: 'biblioteca', component: MostrarBibliotecasComponent},
            { path: 'biblioteca/detalles/:idBiblioteca/:urlBiblioteca', component: BibliotecaDetallesComponent},
            { path: 'notificaciones', component: NotificacionesComponent},
            
        ], canActivate:[AutenticacionGuard]
    },
    {path: 'iniciar-sesion', component: LoginComponent},
    {path: 'recuperar-contrasena', component: RecuperarPasswordComponent},
    {path: 'recuperar-contrasena/:email/:token', component: RecuperarPasswordComponent},
    {path: 'error', component: AppErrorComponent},
    {path: 'accessdenied', component: AppAccessdeniedComponent},
    {path: 'notfound', component: AppNotfoundComponent},
    
    {path: '**', redirectTo: '/notfound'},

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
