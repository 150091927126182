import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  url:string;
  urlTgSite:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
    this.urlTgSite = environment.urlApiSite;
   }

   obtenerNotificaciones() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "notificaciones/user/",{headers});
  }

  obtenerUltimasNotificaciones() {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "notificaciones/user/5",{headers});
  }

  verNotificacion(jsonData:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.url+ "notificaciones/ver/",jsonData,{headers});
  }

  
  verTodasLasNotificaciones(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.post(this.url+ "notificaciones/ver/todas",null,{headers});
  }

  async obtenerBanner(): Promise<any> {
    return await this.http.get<any>(`${this.urlTgSite}?urlCurso=null&proyecto=tg-alumnos`).toPromise();
  }
}
