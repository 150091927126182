import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/tgservicios/usuario.service';
import { Router } from '@angular/router';
import {Message,MessageService} from 'primeng/api';


@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css'],
  providers: [MessageService]
})
export class RecuperarPasswordComponent implements OnInit {
  dark: boolean; //Base de la plantilla

  correoElectronico:string="";
  password:string="";
  password_verify:string="";
  token:string="";

  statusForm:number = 0;
  mensajeName:string;
  mensajeContent:string;
  msgs1: Message[];
  constructor(private activatedRoute: ActivatedRoute, private usuarioService:UsuarioService,
    private route:Router, private messageService: MessageService) {
    this.dark = true;
    this.activatedRoute.params.subscribe ( params => {
      this.correoElectronico = (params.email);
      this.token = (params.token);
    });
  }

  ngOnInit(): void {
  }

  enviarCorreoRecuperacion(){
    if(this.correoElectronico != undefined && this.correoElectronico.length > 0 ){
      let formData: FormData = new FormData();
      formData.append('email', this.correoElectronico);

      this.usuarioService.correoRecuperacion(formData).subscribe(data => {
        this.statusForm = 2;
        this.mensajeName = "Exito";
        this.mensajeContent = "enviamos un correo de recuperacion a tu email.";
        this.route.navigate(['/iniciar-sesion'],  { queryParams: { mensaje: this.mensajeContent } });
      },error => {
      
        if(error.status === 401){
          this.msgs1 = [
            {severity:'error', summary:'Error,', detail:'El usuario no fue encontrado.'} 
          ];
        }else{
          this.statusForm = 1;
          this.mensajeName = "Error"+error.status;
          this.mensajeContent = 'Detalles: '+error.name;
        }
      });
    }else{
      this.statusForm = 1;
      this.mensajeName = "Error";
      this.mensajeContent = 'Debe ingresar un email valido';
    }
  }

  restaurarContrasenia(){
    if(this.password.length < 6){
      this.statusForm = 3;
      this.mensajeName = "Error";
      this.mensajeContent = "Las contraseñas deben tener minimo 6 caracteres.";
    }else if(this.password != this.password_verify){
      this.statusForm = 3;
      this.mensajeName = "Error";
      this.mensajeContent = "Las contraseñas que ingresaste no coinciden.";
    }else{
      let formData: FormData = new FormData();
      formData.append('email', this.correoElectronico);
      formData.append('token', this.token);
      formData.append('password', this.password);
      formData.append('confirmacion', this.password_verify);

      this.usuarioService.restablecerContrasena(formData).subscribe(data => {
        //console.log(data);
        this.statusForm = 4;
        this.mensajeName = "Exito";
        this.mensajeContent = "Tu contraseña se ha restablecido.";
      },error => {
        this.statusForm = 3;
        this.mensajeName = "Error"+error.status;
        this.mensajeContent = 'Detalles: '+error.name;
      });
    }

  }

}
