import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router} from '@angular/router';
import { AutenticacionService } from '../tgservicios/autenticacion.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionGuard implements CanActivate, CanActivateChild {
  
  constructor(private autenticacionService:AutenticacionService, private router:Router){ }

  canActivate(){
    if(localStorage.getItem("token") ){
      var fechaFin = new Date( localStorage.getItem("expiracionToken") ).getTime();
      var fechaActual = new Date().getTime();
      if(fechaActual < fechaFin){
        return true;
      }else{
        this.autenticacionService.logout();
        this.router.navigateByUrl('/iniciar-sesion');
      };
    }else{
      this.router.navigateByUrl('/iniciar-sesion');
      return false;
    }
  }

  canActivateChild(){
    return true;
  }

}
