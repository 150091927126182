import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  
  url:string;

  constructor(private http:HttpClient) {
    this.url = environment.urlApi;
  }

  perfilCompleto(idUsuario:number){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "users/datos-generales/"+idUsuario,{headers});
  }

  todos(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "users/all/",{headers});
  }

  actualizarDatosPersonales(datosUsuario:FormData){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.put(this.url+ "users/actualizar-datos-generales/",datosUsuario,{headers});
  }

  evaluarInstructor(idUsuario){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "users/datos-generales/"+idUsuario,{headers});
  }

  correoRecuperacion(formData:any){
    return this.http.post(this.url+ "login/forgot-password",formData);
  }

  restablecerContrasena(formData:any){
    return this.http.post(this.url+ "login/resset-password",formData);
  }
}
