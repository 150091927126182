import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  
  url:string;
  
  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  materialCurso(idCalendario:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "materiales/curso/"+idCalendario,{headers});
  }

  materialCursoURl(urlCurso:String){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "materiales/url-curso/"+urlCurso,{headers});
  }
  tiposMaterial(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "materiales/tipos",{headers});
  }

  nuevoMaterial(data:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.post(this.url+"materiales/save/material",data,{headers});
  }

  eliminarMaterial(idMaterial:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.delete(this.url+ "materiales/delete/material/"+idMaterial,{headers});
  }

  compartirMaterial(jsonData:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.url+ "materiales/save/compartido",jsonData,{headers});
  }

  materialCompartido(idCalendario:string){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "materiales/compartido/"+idCalendario,{headers});
  }

  eliminarMaterialCompartido(idMaterialCompartido:string){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.delete(this.url+ "materiales/delete/compartido/"+idMaterialCompartido,{headers});
  }
}
