import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsistenciaAlumnoService {
  
  url:string;
 
  constructor(private http: HttpClient, private router: Router) {
    this.url = environment.urlApi;
  }

  /**
    * Metodo que permite obtener los datos del pase de lista desde la API
    * @ params id = idcalendario
  */
  obtenerAsistencia(idCalendario : string ){
    return this.http.get(this.url + 'asistencias/calendario/' + idCalendario,
      {headers: {'Authorization':`Bearer ${localStorage.getItem('token')}`, 'Content-Type':'application/json'}}
    )
  }

  registrarAsistencia(jsonData:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.post(this.url+ "asistencias/registrar",jsonData,{headers});

  }
}
