import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioModel } from '../tgmodelos/Usuario.model';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {
  
  url:string;
  tokenUsuario:string;
  resultado:any;

  constructor(private http:HttpClient, private router:Router) { 
    this.url = environment.urlApi;
  }

  login(usuario: UsuarioModel){
    var jsonData = JSON.stringify({"username":usuario.email, "password":usuario.password});
    const headers = new HttpHeaders({"Content-Type":"application/json;charset=UTF-8"});
    return this.http.post(this.url+'login/authenticate',jsonData,{headers});
  } 

  /**
   * Cerrar sesion
   */
  logout(){
    localStorage.removeItem('idUsuario');
    localStorage.removeItem('idInstructor');
    localStorage.removeItem('idAlumno');
    localStorage.removeItem('nombreUsuario');
    localStorage.removeItem('email');
    localStorage.removeItem('rolUsuario');
    localStorage.removeItem('token');
    this.router.navigateByUrl('/iniciar-sesion');
    
  }

  /** Validacion de inicio de sesion */
  autenticacion(){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token") });
    return this.http.get(this.url+ "users/datos-generales/"+localStorage.getItem("idUsuario"),{headers});
  }

}
