import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { Message, MessageService } from 'primeng/api';
import { BibliotecasService } from 'src/app/tgservicios/bibliotecas.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-biblioteca-detalles',
  templateUrl: './biblioteca-detalles.component.html',
  styleUrls: ['./biblioteca-detalles.component.css'],
  styles: [`
    :host ::ng-deep button {margin-right: .25em;}
    :host ::ng-deep .ui-message,
    :host ::ng-deep .ui-inputtext {margin-right: .25em;}
    :host ::ng-deep .custom-message,
    :host ::ng-deep img  {align-self: center; font-size: 16px; margin-left: .5em;}`
  ],
  providers: [MessageService]
})
export class BibliotecaDetallesComponent implements OnInit {

  msgs:Message[] = [];
  loading:boolean=true;
  
  tipoUsuario:string = localStorage.getItem("rolUsuario");

  idBiblioteca:string;
  urlBiblioteca:string;
  detallesBiblioteca:any=[];
  temarios:any;
  subtemaSeleccionado:any=[];
  urlRecurso:String ="s/r";
  urlArchivosBiblioteca:string = environment.urlArchivosBiblioteca;

  bloquearAcceso:boolean=true;
  
  constructor(private activatedRoute: ActivatedRoute, private breadcrumbService:BreadcrumbService, private bibliotecasService:BibliotecasService) {    

    this.activatedRoute.params.subscribe ( params => {
      this.idBiblioteca = ( params.idBiblioteca );
      this.urlBiblioteca = ( params.urlBiblioteca );
    });

    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Biblioteca', routerLink: ['/biblioteca']},
    ]);
  }

  ngOnInit(): void {
    this.obtenerTemarios();
    this.obtenerDetallesBiblioteca();
  }

  obtenerDetallesBiblioteca(){
    this.bibliotecasService.busquedaById(this.idBiblioteca).subscribe(detallesBiblioteca => { 
      this.detallesBiblioteca = detallesBiblioteca;

      this.breadcrumbService.setItems([
        {label: 'Inicio', routerLink: ['/']},
        {label: 'Biblioteca', routerLink: ['/biblioteca']},
        {label: this.detallesBiblioteca["nombre"]}
      ]);

      //console.log(this.detallesBiblioteca);
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las bibliotecas.', detail:'Detalles: '+error.name});
    });
  }

  obtenerTemarios(){
    this.bibliotecasService.temarioBiblioteca(this.idBiblioteca).subscribe(temarios => {
      //console.log(temarios);
      this.temarios = temarios["temario"];
      this.bloquearAcceso = !temarios['permitirAcceso'];
      console.log(this.bloquearAcceso);
      if( temarios['permitirAcceso'] === true){
        if(temarios["ultimoVisto"] !=null){
          this.subtemaSeleccionado = temarios["ultimoVisto"];
        }else{
          this.subtemaSeleccionado = this.temarios[0]["subtemas"][0];
          if(this.temarios[0]["subtemas"][0]["visto"]==false){
            this.registrarVistaSubtema(this.temarios[0]["subtemas"][0]["idTemaSubtema"]);
            this.subtemaSeleccionado["visto"]=true;
          }
        }
      }
      this.loading = false;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener las bibliotecas.', detail:'Detalles: '+error.name});
    });
  }

  cargarRecursoTemario(subtema:any){
      this.registrarVistaSubtema(subtema["idTemaSubtema"]);
      subtema["visto"]=true;
      this.subtemaSeleccionado = subtema;
      window.scrollTo(0, 100);
  }


  registrarVistaSubtema(idTemaSubtema){
    var subtemaARegistrar = JSON.stringify({ "temasubtema":{"idTemaSubtema":idTemaSubtema}});
    this.bibliotecasService.registrarVistaSubtema(subtemaARegistrar).subscribe(subtema => { 
    },error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error  al  registar la vista  del subtema', detail:''});
    });    
  }
  
}
