import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {

  url:string;

  constructor(private http: HttpClient) {
    this.url = environment.urlApi;
  }

  obtenerDashboard(idInstructor:any){
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem("token"), 'Content-Type': 'application/json' });
    return this.http.get(this.url+ "dashboard/instructor/"+idInstructor,{headers});
  }

  registrarTarea(formData: any ) {
    return this.http.post(this.url + 'publicacion/save', formData,
      {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}}
    );
  }

  eliminarTarea(idPublicacion: number){
    return this.http.delete(this.url + 'publicacion/delete/' + idPublicacion,
      {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/json'}}
    );
  }

}
