import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { CursosService } from '../../../tgservicios/cursos.service';
import { EvaluacionesService } from '../../../tgservicios/evaluaciones.service';
import { Message, MessageService } from 'primeng/api';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-mostrar-cursos',
  templateUrl: './mostrar-cursos.component.html',
  styleUrls: ['./mostrar-cursos.component.css'],
  providers: [MessageService]
})

export class MostrarCursosComponent implements OnInit {
  // Declaración de variables
  rolUsuario:string;
  idCalendario: number;
  cursoPagina:any[] = [];
  limite:number = 12;
  totalCurso:number;
  msjform:boolean; // variable que permite mostrar el msj de error en pantalla al realizar la encuesta
  msjComForm : boolean;
  paginacion: boolean = false; //muestra el div que contiene el paginado
  encuestaPendiente:boolean=false;
  encuestaPendienteModal:boolean=false;
  //Variables de plantillas
  msgs: Message[] = [];
  loading:any = true;

  val1: number;
  formularioExtra: boolean = false; // Variable que permite mostrar p-dropdown
  cursos: any;
  filtroCursos: any[]; //Filtro de busqueda de cursos
  selectedTipo: any = {"nombre":"Cursos existentes"};// Almacena la opción seleccionada en el p-dropdown
  
  
  modalidad: any[] = [ {nombre: 'Presencial'}, {nombre: 'Online'} ]; // contiene las opciones que se muestran en el p-dropdown de modalidad
  localidad: any[] = [  {nombre: 'CDMX'}, {nombre: 'Guadalajara'}, ]; // contiene las opciones que se muestran en el p-dropdown de localidad
  text: string = ''; // Almacena el texto ingresado para la busqueda de cursos

  //Elementos de detalles de curso proximo
  displayDetallesCursoProximo:boolean = false;
  cursoProximoSeleccionado:any[] = [];

  //Elementos de evaluaciones
  displayEvaluaciones: boolean = false;
  nombreEvaluacion: string;
  modalidadEvaluacion: string;
  tipoEvaluacion:string;
  preguntasEvaluacion: string;
  intructorEvaluacion: string;
  enviandoRespuesta: boolean;
  
  constructor(private breadcrumbService: BreadcrumbService, private cursosService: CursosService,
  private evaluacionesService: EvaluacionesService, private messageService: MessageService,private route: ActivatedRoute) {
    this.rolUsuario=localStorage.getItem("rolUsuario");
    
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Cursos'}
    ]);
    
  }

  ngOnInit(): void {
    this.cargarSelectCursos();
    if(localStorage.getItem("rolUsuario") === "admin"){
      this.obtenerCursosActivos();
    }else if(localStorage.getItem("rolUsuario") === "Instructor"){
      this.selectedTipo =  {"nombre":"Cursos asignados"}
      this.obtenerCursosProfesor();
    }else if(localStorage.getItem("rolUsuario") === "student"){

      this.selectedTipo =  {"nombre":"Mis cursos"}
      this.obtenerCursosAlumno();
      this.route.queryParams.subscribe(params => {
        if( params['encuestaPendiente']!=null){ this.encuestaPendiente = true;
         }
      });
    }
    this.encuestaPendienteModal=this.encuestaPendiente;
  }

  cargarSelectCursos(){
    if(localStorage.getItem("rolUsuario") === "admin"){
      this.filtroCursos = [ {nombre: 'Cursos existentes'}, {nombre: 'Proximos cursos'}, {nombre:'Cursos iniciados'} ];
    }else if(localStorage.getItem("rolUsuario") === "Instructor"){
      this.filtroCursos =  [ {nombre: 'Cursos asignados'}, {nombre: 'Mis cursos'} ];
    }else if(localStorage.getItem("rolUsuario") === "student"){
      this.filtroCursos = [  {nombre: 'Proximos cursos'}, {nombre: 'Mis cursos'} ];
    }
  }

  // Variable que almacena el texto escrito en el campo para buscar
  obtenerCursosActivos() {
    this.cursosService.cursosActivos().subscribe(cursos => {
      this.loading = false;
      this.cursos = cursos;
      this.totalCurso = this.cursos.length;
      this.paginar();
      this.paginacion = true;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
    });
  }

  obtenerCursosProximos(){
    this.cursosService.cursosProximos().subscribe(cursos => {
      this.loading = false;
      this.cursos = cursos;
      this.totalCurso = this.cursos.length;
      this.paginar();
      this.paginacion = true;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
    });
  }

  obtenerCursosIniciados(){
    this.cursosService.cursosIniciados().subscribe(cursos => {
      this.loading = false;
      this.cursos = cursos;
      this.totalCurso = this.cursos.length;
      this.paginar();
      this.paginacion = true;
      //console.log(this.cursos);
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos.', detail:'Detalles: '+error.name});
    });
  }

  bloquearAccesoPorEncuestaPendiente(indexCurso:any){
    let status=false;
    try{
      if(this.cursos[indexCurso]["calendario"]["curso"]["encuestas"] != undefined ){
        for(let encuesta of this.cursos[indexCurso]["calendario"]["curso"]["encuestas"]){
          if(encuesta["status"] === false) status = true;
        }
      }else{ status = false; }
    }catch(error){ }
    return status;
  }

  // Metodo que recibe el resultado del servicio de la api "Cursos donde se encuentra inscrito el alumno"
  obtenerCursosAlumno() {
    this.cursosService.cursosAlumno(localStorage.getItem('idAlumno')).subscribe(cursosAlumno => {
      this.loading = false;
      this.cursos = cursosAlumno[0]["inscripcion"];
      //console.log(this.cursos);
      this.totalCurso = this.cursos.length;
      this.paginar();
      this.paginacion = true;

      this.encuestaPendiente = false;

      try{
        for(let curso of this.cursos){
          if(curso["calendario"]["curso"]["encuestas"] == undefined){
            break
          }
          for (let encuesta of curso["calendario"]["curso"]["encuestas"]) {
            if(encuesta["status"] === false){
              this.encuestaPendiente = true;
              break
            }
          }
        }
      }catch(err){}

    }, error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los cursos del alumno.', detail:'Detalles: '+error.name});
    });
  }

  // Metodo que recibe el resultado del servicio de la api "Cursos que se encuentra impartiendo el instructor"
  obtenerCursosProfesor() {
    this.cursosService.cursosInstructor(localStorage.getItem('idInstructor')).subscribe(cursosInstructor => {
      this.loading = false;
      this.cursos = cursosInstructor[0]["calendario"];
      //console.log(this.cursos);
      this.totalCurso = this.cursos.length;
      this.paginar();
      this.paginacion = true;
    },error => {
      this.loading = false;
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener cursos del instructor.', detail:'Detalles: '+error.name});
    });
  }

  /*
  * tipo: variable que contiene el select del pdropdown
  * Metodo que permite almacenar los cursos del usuario según corresponda
  */
  filtrarCurso(tipo:any) {
    this.msgs = [];
    this.cursos = undefined;
    this.paginacion = false;
    this.loading = true;
    if (tipo["nombre"] == 'Mis cursos') {
      if(localStorage.getItem("idAlumno") != 'undefined'){
        this.obtenerCursosAlumno();
        this.loading = false;
      }else{
        this.msgs = [];
        this.msgs.push({severity:'info', summary:'', detail:'No estas registrado como un alumno.'});
        this.loading = false;
      }
      
    } else if (tipo["nombre"] == 'Cursos asignados') {
      this.obtenerCursosProfesor();
    }else if(tipo["nombre"] == 'Cursos existentes'){
      this.obtenerCursosActivos();
    }else if(tipo["nombre"] == 'Cursos iniciados'){
      this.obtenerCursosIniciados();
    }else{
      this.obtenerCursosProximos();
    }
  }

  // Paginar cursos 
  paginar(){ 
    this.cursoPagina = [];
    for (let index = 0; index < this.limite; index++) { this.cursoPagina.push(this.cursos[index]); }
  }

  paginate(event:any) {
    let ultimapagina:number = event.pageCount - 1;
    this.cursoPagina = [];
    let limiteinicio =  this.limite * event.page; 
    if(event.page > 0 && event.page != ultimapagina){
      for (let index = limiteinicio; index < limiteinicio + this.limite  ; index ++) { this.cursoPagina.push(this.cursos[index]); }
    }else if(ultimapagina == event.page){
      let limitefin = this.cursos.length - limiteinicio;
      for (let index = limiteinicio; index < limiteinicio + limitefin ; index++) { this.cursoPagina.push(this.cursos[index]); }
    }else{
      this.paginar(); //primera pagina
    }
  }


  /** METODOS DEL FORMULARIO DE EVALUACIONES */
  mostrarFormularioEvaluaciones(tipoEvaluacion:string, idCalendario:any) {
    this.idCalendario = idCalendario;
    
    if(tipoEvaluacion === "Intermedia") {
      this.tipoEvaluacion = "intermedia";
    } else {
      this.tipoEvaluacion = "final";
    }

    this.evaluacionesService.obtenerEvaluacionCurso(this.tipoEvaluacion,idCalendario,String(localStorage.getItem("idAlumno"))).subscribe(datosEncuesta =>{
      this.nombreEvaluacion = datosEncuesta[0]["curso"];
      this.modalidadEvaluacion = datosEncuesta[0]["modalidad"];
      this.intructorEvaluacion = datosEncuesta[0]["instructor"];
      this.preguntasEvaluacion = datosEncuesta[0]["preguntasEncuestas"];
      //console.log(this.preguntasEvaluacion );
      
      // console.log(this.preguntasEvaluacion);
      this.displayEvaluaciones = true;
      this.enviandoRespuesta = false;
    },error =>{
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al cargar la encuesta.', detail:'Detalles: '+error.name});
    });
    
  }
  mostrarDetallesCursoProximo(i:number){
    this.cursoProximoSeleccionado = this.cursoPagina[i];
    this.displayDetallesCursoProximo = true;
  }
  //Funcion que permir guardar la encuesta de satisfacción 
  guardarEncuesta(form: NgForm){
    if (form.invalid) {
      this.msjform = true;
      setTimeout(() => {
        this.msjform = false;
      }, 3000);
      this.mostrarMensajeError(form);
    } else {
      let tipo;

      if(this.tipoEvaluacion === 'intermedia'){tipo = {'tipo':false}; }else if(this.tipoEvaluacion === 'final'){ tipo = {'tipo':true}; }
      let respuestas = form.value;
      let examQualityResponse = {...tipo,...respuestas};
      this.enviandoRespuesta = true;

      let jsonData = JSON.stringify({'idCalendario': this.idCalendario,'idUser': localStorage.getItem('idUsuario'), examQualityResponse});
      // console.log(jsonData);

      this.evaluacionesService.realizarEvaluacion(jsonData).subscribe((data: any) => {
        this.obtenerCursosAlumno();
        this.displayEvaluaciones = false;
        this.msgs = [];
        this.msgs.push({severity: 'success', summary: 'Encuesta realizada con exito'});
      }, error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status, detail:'Detalles: '+error.name});
      });
    } 
  }

  mostrarMensajeError(form: NgForm){
    if(form.value.respuesta1 == ''){
      if(!form.value.respuesta1){
      const id = "mensajeErrorRes1";
      document.getElementById(id).style.display = "block";
      }
    }
    if(form.value.respuesta2 == ''){
      if(!form.value.respuesta2){
      const id = "mensajeErrorRes2";
      document.getElementById(id).style.display = "block";
      }
    }
    if(form.value.respuesta3 == ''){
      if(!form.value.respuesta3){
      const id = "mensajeErrorRes3";
      document.getElementById(id).style.display = "block";
      }
    }
    if(form.value.respuesta4 == ''){
      if(!form.value.respuesta4){
      const id = "mensajeErrorRes4";
      document.getElementById(id).style.display = "block";
      }
    }
    if(form.value.respuesta5 == ''){
      if(!form.value.respuesta5){
      const id = "mensajeErrorRes5";
      document.getElementById(id).style.display = "block";
      }
    }
  }

  seleccionado(form: NgForm){
    if(form.value.respuesta1){
      const id = "mensajeErrorRes1";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.respuesta2){
      const id = "mensajeErrorRes2";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.respuesta3){
      const id = "mensajeErrorRes3";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.respuesta4){
      const id = "mensajeErrorRes4";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.respuesta5){
      const id = "mensajeErrorRes5";
      document.getElementById(id).style.display = "none";
    }
  }
  ocultarDiv(form: NgForm){
    if(form.value.comentario3){
      const id = "mensajeErrorCom3";
      const cantidadComentario3 = form.value.comentario3.length;
      if(cantidadComentario3 >= 20){
        document.getElementById(id).style.display = "none";
      }else{
        document.getElementById(id).style.display = "block";
      }
    }else if(form.value.comentario3 == ''){
      const id = "mensajeErrorCom3";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.comentario4){
      const id = "mensajeErrorCom4";
      const cantidadComentario = form.value.comentario4.length;
      if(cantidadComentario >= 20 || cantidadComentario == 0){
        document.getElementById(id).style.display = "none";
      }else{
        document.getElementById(id).style.display = "block";
      }
    }else if(form.value.comentario4 == ''){
      const id = "mensajeErrorCom4";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.comentario5){
      const id = "mensajeErrorCom5";
      const cantidadComentario = form.value.comentario5.length;
      if(cantidadComentario >= 20 || cantidadComentario == 0){
        document.getElementById(id).style.display = "none";
      }else{
        document.getElementById(id).style.display = "block";
      }
    }else if(form.value.comentario5 == ''){
      const id = "mensajeErrorCom5";
      document.getElementById(id).style.display = "none";
    }
    if(form.value.comentario6 ){
      const id = "mensajeErrorCom6";
      const cantidadComentario = form.value.comentario6.length;
      if(cantidadComentario >= 20 || cantidadComentario == 0){
        document.getElementById(id).style.display = "none";
      }else{
        document.getElementById(id).style.display = "block";
      }
    }else if(form.value.comentario6 == ''){
      const id = "mensajeErrorCom6";
      document.getElementById(id).style.display = "none";
    }
  }
}
