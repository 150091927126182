import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicacionService } from '../../../tgservicios/publicacion.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Message, MessageService } from 'primeng/api';
import { NgForm} from '@angular/forms';

@Component({
  selector: 'app-publicacion-detalle',
  templateUrl: './publicacion-detalle.component.html',
  styleUrls: ['./publicacion-detalle.component.css'],
  providers: [MessageService]
})
export class PublicacionDetalleComponent implements OnInit {
  
  // Declaración de variables
  @ViewChild('fileUpload') fileUpload: any ; //permite manipular los metodos de <p-file>
  msgs: Message[] = []; // permite mostrar un msj de error
  nombreCurso:string; // Almacena nombre del curso
  idCalendario: string; // Variable que almacena el identificador del curso
  idPublicacion: string; // Variable que almacena el identificador del la publicacion
  nombrePublicacion:string; //nombre de la publicacion
  archivosPublicacion:any; //archivos de la publicacion
  detallePublicacion: string;
  idCurso: string;
  
  comentarios:any; //Comentarios de la publicacion

  publicacion: any[] = []; // VAriable que almacena la publicacion en especifico
  resultado:any[];
  cursoActual: any [] = []; // Almacena el curso actual

  displayWindow: boolean; // Variable para mostrar la ventana
  mostrarmsj: boolean = false;
  msjform: boolean = false;
  textoComentario: string;
  uploadedFiles: any[] = [];
  archivos: any[] = [];
  idUsuario: string; // variable que se recupera el id usuario
  nuevoComentario: any[] = [];

  rowGroupMetadata: any;

  constructor(private breadcrumbService: BreadcrumbService, private _activatedRoute: ActivatedRoute, private messageService: MessageService,
  private _publicacionService: PublicacionService) {

    this.idUsuario = localStorage.getItem('idUsuario');
    // obtener datos url
    this._activatedRoute.params.subscribe (params => {
      this.nombreCurso = params ['nombreCurso'];
      this.idCalendario = params['idCalendario'];
      this.idCurso = params['idCurso'];
      this.nombrePublicacion = params ['nombrePublicacion']
      this.idPublicacion = params['idPublicacion'];
   
    });

    //menu navegacional
    this.breadcrumbService.setItems([
      {label: 'Inicio', routerLink: ['/']},
      {label: 'Cursos', routerLink: ['/cursos/' + localStorage.getItem('idUsuario')]},
      {label: this.nombreCurso, routerLink: ['/curso/'+ this.idCurso+ '/'+ this.nombreCurso + '/'+ this.idCalendario]},
      {label: this.nombrePublicacion}
    ]);
    
  }

  ngOnInit(): void {
    this.obtenerComentarios();
   
  }

  // Metodo para obtener todoas las publicaciones del curso
  obtenerComentarios() {
    this._publicacionService.obtenerComentarios(this.idPublicacion).subscribe(comentarios => {
      //console.log(comentarios);
      var datos = comentarios["0"];
      this.nombrePublicacion = datos["nombrePublicacion"];
      this.archivosPublicacion = datos["archivos"];
      this.comentarios = datos["comentarios"];
      this.detallePublicacion = datos["texto"];
      this.updateRowGroupMetaData();
    }, error => {
      this.msgs = [];
      this.msgs.push({severity:'error', summary:'Error '+error.status+' al obtener los comentarios.', detail:'Detalles: '+error.name});
    });
  }

  guardarComentario(form: NgForm) {
    if (form.invalid) {
      this.msjform = true;
    }else{
      // recuperar la información  y mandar a la API
      let formData: FormData = new FormData();
      if(this.archivos.length == 0) {
        formData.append('publicacionusers', this.idPublicacion);
        formData.append('comentario', this.textoComentario);
        formData.append('users', localStorage.getItem('idUsuario'));
        // mandar petición a la API sin archivo
      }else{
        formData.append('publicacionusers', this.idPublicacion);
        formData.append('comentario', this.textoComentario);
        formData.append('users', localStorage.getItem('idUsuario'));
        // mandar petición a la API con archivo
        /* Archivos multiples */
        for(let i = 0; i < this.archivos.length; i++) {formData.append('file', this.archivos[i], this.archivos[i].name);}
      }

      /* Consumir la API Registrar la comentario */
      this._publicacionService.registrarComentario(formData).subscribe ((data: any) => {
        this.obtenerComentarios();
        this.displayWindow = false;
        this.limpiarFormulario(form);
        this.msgs = [];
        this.msgs.push({severity:'success', summary:'Exito, ', detail:' tu comentario se registro correctamente.'});
      },error => {
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error '+error.status+' al registrar el comentario', detail:'Detalles: '+error.name});
      });
    }
  }

  mostrarVentana() {
    this.displayWindow = true;
    this.fileUpload.clear();
    this.mostrarmsj = false ;
  }

  onSelectedFile(event: any) {
    /*Multiples Archivos*/
    if(event.currentFiles.length > 0 ){
      this.archivos = event.currentFiles;
    }
  }

  onUpload(event) {
    if (event.target.files.length > 0) {
      for (const file of event.files) { this.uploadedFiles.push(file); }
    }
  }
  
  limpiarFormulario(formulario: NgForm) {
    /**Limipiar todos los archivos archivos */
    formulario.reset();
    this.fileUpload.clear();
  }


  onSort() { this.updateRowGroupMetaData(); }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.comentarios) {
      for (let i = 0; i < this.comentarios.length; i++) {
        let rowData = this.comentarios[i];
        let name = rowData.name;
        if (i == 0) {
          this.rowGroupMetadata[name] = { index: 0, size: 1 };
        }else{
          let previousRowData = this.comentarios[i - 1];
          let previousRowGroup = previousRowData.name;
          if (name === previousRowGroup) this.rowGroupMetadata[name].size++;
          else this.rowGroupMetadata[name] = { index: i, size: 1 };
        }
      }
    }
  }

  

}
